import { Column, ERowAlignment, Row } from '@hyperclap/ui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import {
    // IconGraphics,
    // IconLayout,
    IconStreamoji,
    IconText,
    IconTextBox,
    IconTitle,
} from './assets';
import { FormSwitcher, MenuItem } from './components';
import s from './Elements.scss';
import { useElements } from './hooks';
import { EElementMenuItem } from '../../enums';
import { useAlertsSettingsType } from '../../hooks';

interface IElementsProps {
    alertsSettingsType: ReturnType<typeof useAlertsSettingsType>;
}

const MENU_ICONS_MAP: Record<EElementMenuItem, React.FunctionComponent<React.SVGProps<SVGSVGElement>>> = {
    // [EElementMenuItem.GRAPHICS]: IconGraphics,
    // [EElementMenuItem.LAYOUT]: IconLayout,
    [EElementMenuItem.STREAMOJI]: IconStreamoji,
    [EElementMenuItem.TEXT]: IconText,
    [EElementMenuItem.TEXT_BOX]: IconTextBox,
    [EElementMenuItem.TITLE]: IconTitle,
};
const MENU_ITEMS = Object.values(EElementMenuItem).map((item) => ({
    id: item,
    icon: MENU_ICONS_MAP[item],
}));

export const Elements = (props: IElementsProps) => {
    const { alertsSettingsType } = props;
    const {
        activeMenuItem,
        onActiveMenuItemChange,
    } = useElements();
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`streamer-settings.alerts-type.${id}`);

    const handleMenuItemClick = (item: EElementMenuItem) => () => {
        onActiveMenuItemChange(item);
    };

    return (
        <Column>
            <Row alignment={ERowAlignment.TOP}>
                <Column className={s.leftColumn}>
                    {MENU_ITEMS.map((item) => (
                        <MenuItem
                            key={item.id}
                            icon={item.icon}
                            title={t(`elementsList.${item.id}`)}
                            isActive={activeMenuItem === item.id}
                            onClick={handleMenuItemClick(item.id)}
                        />
                    ))}
                </Column>
                <Column className={s.rightColumn}>
                    <FormSwitcher
                        alertsSettingsType={alertsSettingsType}
                        activeMenuItem={activeMenuItem}
                    />
                </Column>
            </Row>
        </Column>
    );
};
