import { ERowAlignment, ISelectOption } from '@hyperclap/ui';
import cn from 'classnames';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { StreamojiPosition, StreamojiSize, StreamojiType, StrokeSize } from '@typings';

import {
    IconPositionBottom,
    IconPositionLeft,
    IconPositionRight,
    IconPositionTop,
    IconStyleBody,
    IconStyleCircle,
    IconStylePortrait,
    IconStyleSquare,
} from './assets';
import s from './StreamojiForm.scss';
import { useAlertsSettingsType } from '../../../../../../../hooks';
import { IconRadioGroup, IIconRadioGroupItem } from '../../../../../../IconRadioGroup';
import { Field } from '../../Field';
import { FieldColorPicker } from '../../FieldColorPicker';
import { FieldEffects } from '../../FieldEffects';
import { FieldStroke } from '../../FieldStroke';
import { FieldSwitcher } from '../../FieldSwitcher';
import { FormRow } from '../../FormRow';


interface IStreamojiFormProps {
    alertsSettingsType: ReturnType<typeof useAlertsSettingsType>;
}

export const StreamojiForm = (props: IStreamojiFormProps) => {
    const {
        alertsSettingsType: {
            alertStyle: {
                alertStyle: {
                    avatar: {
                        enabled,
                        position,
                        type,
                        size,
                        background,
                        stroke,
                        effects,
                    },
                },
            },
            strokeSizeList,
            visualEffectInList,
            visualEffectOutList,
            visualEffectSpeedList,
            onAvatarEnabledChange,
            onAvatarTypeChange,
            onAvatarStrokeSizeChange,
            onAvatarStrokeFillChange,
            onAvatarBackgroundFillChange,
            onAvatarPositionChange,
            onAvatarSizeChange,
            onAvatarEffectsChange,
        },
    } = props;
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`streamer-settings.alerts-type.forms.${id}`);

    const handleStrokeSizeChange = (selection?: ISelectOption) => {
        onAvatarStrokeSizeChange(selection?.value as StrokeSize);
    };

    const positionItems: IIconRadioGroupItem[] = [
        {
            value: StreamojiPosition.TOP,
            title: t('top'),
            icon: IconPositionTop,
        },
        {
            value: StreamojiPosition.BOTTOM,
            title: t('bottom'),
            icon: IconPositionBottom,
        },
        {
            value: StreamojiPosition.LEFT,
            title: t('left'),
            icon: IconPositionLeft,
        },
        {
            value: StreamojiPosition.RIGHT,
            title: t('right'),
            icon: IconPositionRight,
        },
    ];
    const isStrokeDisabled = type === StreamojiType.TRANSPARENT || type === StreamojiType.FULLSIZE;

    return (
        <div className={s.root}>
            <FormRow>
                <FieldSwitcher
                    label={t('display')}
                    checked={enabled}
                    onChange={onAvatarEnabledChange}
                />
            </FormRow>

            <FormRow alignment={ERowAlignment.TOP}>
                <Field
                    label={t('position')}
                    content={
                        <IconRadioGroup
                            classNames={{
                                item: s.positionItem,
                            }}
                            items={positionItems}
                            value={position}
                            onChange={onAvatarPositionChange}
                        />
                    }
                />
            </FormRow>

            <FormRow>
                <Field
                    label={t('size')}
                    content={
                        <IconRadioGroup
                            classNames={{
                                item: s.sizeItem,
                                itemActive: s.sizeItemActive,
                                icon: s.sizeItemIcon,
                            }}
                            items={[
                                {
                                    value: StreamojiSize.XS,
                                    title: t('sizes.xs'),
                                    icon: ({ className }) => <div className={cn(s.sizeIcon, className)}/>,
                                    className: s.sizeItemXS,
                                },
                                {
                                    value: StreamojiSize.S,
                                    title: t('sizes.s'),
                                    icon: ({ className }) => <div className={cn(s.sizeIcon, className)}/>,
                                    className: s.sizeItemS,
                                },
                                {
                                    value: StreamojiSize.M,
                                    title: t('sizes.m'),
                                    icon: ({ className }) => <div className={cn(s.sizeIcon, className)}/>,
                                    className: s.sizeItemM,
                                },
                                {
                                    value: StreamojiSize.L,
                                    title: t('sizes.l'),
                                    icon: ({ className }) => <div className={cn(s.sizeIcon, className)}/>,
                                    className: s.sizeItemL,
                                },
                            ]}
                            value={size}
                            onChange={onAvatarSizeChange}
                        />
                    }
                />
            </FormRow>

            <FormRow>
                <Field
                    label={t('style')}
                    content={
                        <IconRadioGroup
                            classNames={{
                                item: s.styleItem,
                            }}
                            items={[
                                {
                                    value: StreamojiType.ROUND,
                                    title: t('avatarStyle.round'),
                                    icon: IconStyleCircle,
                                },
                                {
                                    value: StreamojiType.SQUARE,
                                    title: t('avatarStyle.square'),
                                    icon: IconStyleSquare,
                                },
                                {
                                    value: StreamojiType.TRANSPARENT,
                                    title: t('avatarStyle.transparent'),
                                    icon: IconStylePortrait,
                                },
                                {
                                    value: StreamojiType.FULLSIZE,
                                    title: t('avatarStyle.fullsize'),
                                    icon: IconStyleBody,
                                },
                            ]}
                            value={type}
                            onChange={onAvatarTypeChange}
                        />
                    }
                />
            </FormRow>

            <FormRow>
                <FieldColorPicker
                    value={background}
                    caption={t('background')}
                    onChange={onAvatarBackgroundFillChange}
                />
            </FormRow>

            <FormRow>
                <FieldStroke
                    classNameSelect={s.stroke}
                    classNameControlInput={s.strokeControlInput}
                    options={strokeSizeList.map((item) => ({
                        caption: item.title,
                        value: item.value,
                        selected: stroke.size === item.value,
                    }))}
                    fill={stroke.fill}
                    disabled={isStrokeDisabled}
                    onSelectionChanged={handleStrokeSizeChange}
                    onFillChange={onAvatarStrokeFillChange}
                />
            </FormRow>

            <FormRow>
                <FieldEffects
                    visualEffectInList={visualEffectInList}
                    visualEffectOutList={visualEffectOutList}
                    visualEffectSpeedList={visualEffectSpeedList}
                    value={effects}
                    onChange={onAvatarEffectsChange}
                />
            </FormRow>
        </div>
    );
};
