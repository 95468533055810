import { Label } from '@hyperclap/ui';
import cn from 'classnames';
import * as React from 'react';

import s from './Field.scss';

export interface IBaseFieldProps {
    label?: string;
}

interface IFieldProps {
    className?: string;
    classNameLabel?: string;
    label?: string;
    disabled?: boolean;
    content: React.ReactNode;
}

export const Field = (props: IFieldProps) => {
    const {
        className,
        classNameLabel,
        label,
        disabled,
        content,
    } = props;

    return (
        <div className={cn(s.root, className)}>
            {label && (
                <Label
                    className={cn(s.label, classNameLabel)}
                    caption={label}
                    disabled={disabled}
                />
            )}
            {content}
        </div>
    );
};
