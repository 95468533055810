export enum EDuration {
    S_10 = '10',
    S_20 = '20',
    S_30 = '30',
}

export enum EOpacity {
    O_0 = '0',
    O_10 = '10',
    O_20 = '20',
    O_30 = '30',
    O_40 = '40',
    O_50 = '50',
    O_60 = '60',
    O_70 = '70',
    O_80 = '80',
    O_90 = '90',
    O_100 = '100',
}

export enum EMargin {
    M_0 = '0',
    M_10 = '10',
    M_20 = '20',
    M_30 = '30',
    M_40 = '40',
    M_50 = '50',
    M_60 = '60',
    M_70 = '70',
    M_80 = '80',
    M_90 = '90',
    M_100 = '100',
}

export enum EFontFamily {
    ARIAL = 'Arial',
    ABIBAS = 'Abibas',
    ALKONAUT_V1_0 = 'Alkonaut v1.0',
    AMADOR_GOTHICC = 'Amador GothicC',
    AMATICSC_REGULAR = 'AmaticSC-Regular',
    ANGLECIAPROTITLE_REGULAR_WEBFONT = 'AngleciaProTitle-Regular-webfont',
    ANIME_ACE_V05 = 'Anime Ace v05',
    ARCHITECTURAL = 'Architectural',
    ARCHIVE = 'Archive',
    ARSENAL_REGULAR = 'Arsenal-Regular',
    ATTENTICA_4F_ULTRALIGHT = 'Attentica 4F UltraLight',
    AVER = 'Aver',
    BIPS = 'BIPs',
    BOOZY_REGULAR = 'BOOZY REGULAR',
    BANANABRICK = 'BananaBrick',
    BEBASNEUE_REGULAR = 'BebasNeue-Regular',
    BELEPOTAN_RUS = 'Belepotan Rus',
    BELLOTA_BOLD = 'Bellota-Bold',
    BELLOTA_REGULAR = 'Bellota-Regular',
    BEMOUNT = 'Bemount',
    BETINASCRIPTEXTRACTT = 'BetinaScriptExtraCTT',
    BIOLILBERT_RG = 'Biolilbert-Rg',
    BLACKCRAFT = 'Blackcraft',
    BLACKENTINA_4F = 'Blackentina 4F',
    BLUECURVE_REGULAR = 'Bluecurve-Regular',
    BRYGADA1918_REGULAR = 'Brygada1918-Regular',
    CAPTAINCOMIC = 'CaptainComic',
    CARAVAN = 'Caravan',
    CATACOMBS_V1_0 = 'Catacombs V1-0',
    CATTEDRALE_RUSBYPENKA220__REGULAR = 'Cattedrale[RUSbypenka220]-Regular',
    CAVEAT_REGULAR = 'Caveat-Regular',
    CHARMING = 'Charming',
    CLINICAPRO_REGULAR = 'ClinicaPro-Regular',
    CODENAMECODERFREE4F_BOLD = 'CodenameCoderFree4F-Bold',
    COLUS_REGULAR = 'Colus-Regular',
    COMFORTAA_REGULAR = 'Comfortaa-Regular',
    COMIC_SANS_MS = 'Comic Sans MS',
    COMICHELVETIC_MEDIUM = 'ComicHelvetic_Medium',
    CORKI_REGULAR = 'Corki-Regular',
    CUPRUM_REGULAR = 'Cuprum-Regular',
    CUTOUTOFFONT = 'CutOutOFFont',
    DAYS = 'Days',
    DEBBY = 'Debby',
    DEWBERRY_REGULAR = 'Dewberry regular',
    DOUBLETWO_STUDIOS___XXIICENTARREGULAR = 'Doubletwo Studios - XXIICentarRegular',
    ELEVENTH_SQUARE = 'Eleventh-Square',
    EPILEPSYSANS = 'EpilepsySans',
    FANTAZYOR = 'Fantazyor',
    FIRAMONO_REGULAR = 'FiraMono-Regular',
    FIRSTTIMEWRITING_DOY8D = 'FirstTimeWriting-DOy8d',
    FLOW = 'Flow',
    FOWVIEL = 'Fowviel',
    FREERIDE = 'Freeride',
    GALAKTIKA = 'Galaktika',
    GARDENSC = 'GardensC',
    GEOMETRIA = 'Geometria',
    GETVOIP_GROTESQUE = 'GetVoIP Grotesque',
    GHASTLYPANICCYR = 'GhastlyPanicCyr',
    GOODVIBESCYR = 'GoodVibesCyr',
    HARDPIXEL = 'Hardpixel',
    INTER_REGULAR = 'Inter-Regular',
    INTRO = 'Intro',
    IRCH_REGULAR = 'Irch-Regular',
    IVAN = 'Ivan',
    IZHITSAC = 'IzhitsaC',
    JIKHAREV = 'Jikharev',
    KANITCYRILLIC = 'KanitCyrillic',
    LINEYKA_FREE = 'Lineyka Free',
    LOBSTER_REGULAR = 'Lobster-Regular',
    MB_REAL_GRINDER = 'MB-Real_Grinder',
    MEGEN = 'Megen',
    METRO = 'Metro',
    MISTERBRUSH = 'MisterBrush',
    MONTSERRAT_REGULAR = 'Montserrat-Regular',
    NEW_ZELEK = 'New_Zelek',
    OPENSANSCONDENSED_BOLD = 'OpenSansCondensed-Bold',
    OSWALD = 'Oswald',
    PTSANS = 'PTSans',
    PTSANSCAPTION = 'PTSansCaption',
    PTSERIF = 'PTSerif',
    PALADINPCRUS = 'PaladinPCRus',
    PALQA_REGULAR = 'Palqa-regular',
    PHILOSOPHER_REGULAR = 'Philosopher-Regular',
    PLUP = 'Plup',
    POMIDOR = 'Pomidor',
    RAMONA_LIGHT = 'Ramona-Light',
    RHYTHMIC_RUS = 'Rhythmic_RUS',
    ROBOTO_REGULAR = 'Roboto-Regular',
    SALONGBEACH = 'SALongBeach',
    SS_ADEC2_0_TEXT = 'SS_Adec2.0_text',
    ST_IZHORA_FREE = 'ST-Izhora-free',
    SATYRSP = 'SatyrSP',
    SOYUZ_GROTESK_BOLD = 'Soyuz Grotesk Bold',
    SPORTSWORLD = 'SportsWorld',
    STENGAZETA_REGULAR_5 = 'Stengazeta-Regular_5',
    SWAMPY_CLEAN = 'Swampy Clean',
    TDTEREMOK = 'TDTeremok',
    TLHEADER_REGULAR_RUS = 'TLHeader-Regular-RUS',
    TKACHENKOSKETCH4F_REGULAR = 'TkachenkoSketch4F-Regular',
    TORHOK = 'Torhok',
    TRAFARET_SHERBACKOFFALEX_CYR = 'Trafaret Sherbackoffalex Cyr',
    TRAKTORMOODFONT_REGULAR = 'TraktorMoodFont-Regular',
    UNBOUNDED_REGULAR = 'Unbounded-Regular',
    UNUTTERABLE_Z8E8X = 'Unutterable-z8E8X',
    VIAODALIBRE_REGULAR = 'ViaodaLibre-Regular',
    XOLONIUM_REGULAR = 'Xolonium-Regular',
    ZAYCHIK_REGULAR = 'Zaychik-Regular',
    AA_BLOODY_NORMAL = 'aa_bloody_normal',
    AZOFT_SANS = 'azoft-sans',
    BION_BOLD = 'bion-bold',
    BITCELL_MEMESBRUH03 = 'bitcell_memesbruh03',
    BULKY = 'bulky',
    COLDNIGHTFORALLIGATORS = 'coldnightforalligators',
    COOLVETICA_RG = 'coolvetica rg',
    CRAMATEN = 'cramaten',
    EASTSEADOKDOCYRILLIC = 'eastseadokdocyrillic',
    EE_BELLFLOWERRUSBYLYAJKA = 'ee_bellflowerrusbylyajka',
    HELVETICACY = 'helveticacy',
    IMPACT = 'impact',
    KOMIKATITLE_AXISRUS_LAT = 'komikatitle_axisrus_lat',
    MACHERIE = 'macherie',
    MINECRAFT_TITLE_CYRILLIC = 'minecraft_title_cyrillic',
    MR_INSULAG = 'mr_insulag',
    PACIFICA = 'pacifica',
    SRIRACHACYRILLIC = 'srirachacyrillic',
    TOLKIENCYR = 'tolkiencyr',
    V_BADABOOMBB_V1_9 = 'v_BadaBoomBB_v1.9',
    V_CCFACEFRONT_V1_28 = 'v_CCFaceFront_v1.28',
    V_CCSOLILOQUOUS_V1_05 = 'v_CCSoliloquous_v1.05',
    V_TYPOGRAPHY_OF_COOP_LATIN_0 = 'v_Typography of Coop Latin_0',
    VINQUE_RG = 'vinque rg',
}

export enum EElementMenuItem {
    // LAYOUT = 'layout',
    STREAMOJI = 'streamoji',
    TITLE = 'title',
    TEXT = 'text',
    TEXT_BOX = 'textBox',
    // GRAPHICS = 'graphics',
}
