import { ERowAlignment, Row } from '@hyperclap/ui';
import * as React from 'react';

import s from './PositionAndSize.scss';
import { useAlertsSettingsType } from '../../hooks';
import { DisplayPosition } from '../DisplayPosition';
import { SizeSelector } from '../SizeSelector';


interface IPositionAndSizeProps {
    alertsSettingsType: ReturnType<typeof useAlertsSettingsType>;
}

export const PositionAndSize = (props: IPositionAndSizeProps) => {
    const {
        alertsSettingsType: {
            alertStyle: {
                position,
                size,
            },
            onAlertPositionChange,
            onAlertSizeChange,
        },
    } = props;

    return (
        <Row alignment={ERowAlignment.CENTER}>
            <DisplayPosition
                className={s.position}
                position={position}
                onChange={onAlertPositionChange}
            />

            <SizeSelector
                size={size}
                onChange={onAlertSizeChange}
            />
        </Row>
    );
};
