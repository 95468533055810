import React from 'react';
import { useTranslation } from 'react-i18next';

import { AlertSize } from '@typings';

import {
    IconSizeXS,
    IconSizeS,
    IconSizeM,
    IconSizeL,
    IconSizeXL,
} from './assets';
import s from './SizeSelector.scss';
import { IconRadioGroup } from '../IconRadioGroup';

interface ISizeSelectorProps {
    size: AlertSize;
    onChange(value: AlertSize): void;
}

export const SizeSelector = (props: ISizeSelectorProps) => {
    const {
        size,
        onChange,
    } = props;
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`streamer-settings.alerts-type.forms.${id}`);

    return (
        <IconRadioGroup
            classNames={{
                item: s.item,
            }}
            items={[
                {
                    value: AlertSize.XS,
                    title: t('sizes.xs'),
                    icon: IconSizeXS,
                },
                {
                    value: AlertSize.S,
                    title: t('sizes.s'),
                    icon: IconSizeS,
                },
                {
                    value: AlertSize.M,
                    title: t('sizes.m'),
                    icon: IconSizeM,
                },
                {
                    value: AlertSize.L,
                    title: t('sizes.l'),
                    icon: IconSizeL,
                },
                {
                    value: AlertSize.XL,
                    title: t('sizes.xl'),
                    icon: IconSizeXL,
                },
            ]}
            value={size}
            onChange={onChange}
        />
    );
};
