import { useEffect, useState } from 'react';

import { useApi } from '@hooks';
import { ISticker } from '@typings';

const player = new Audio();

export const useSoundSelector = () => {
    const {
        stickers: {
            useLoadAlertsSoundsQuery,
        },
    } = useApi();
    const { data: alertsSoundsList, isSuccess: isLoadSuccess } = useLoadAlertsSoundsQuery();
    const [alertsSounds, setAlertsSound] = useState<ISticker[]>([]);
    const [query, setQuery] = useState('');
    const [played, setPlayed] = useState<ISticker>();
    const isLoading = !isLoadSuccess;

    const onQueryChange = (value: string) => setQuery(value.trim());

    const onPlayedChange = (value?: ISticker) => setPlayed(value);

    useEffect(() => {
        if (alertsSoundsList) {
            setAlertsSound(alertsSoundsList);
        }
    }, [alertsSoundsList]);

    useEffect(() => {
        if (alertsSoundsList) {
            if (query.length) {
                setAlertsSound(
                    alertsSoundsList.filter(
                        (item) => item.name.toLowerCase().includes(query.toLowerCase()),
                    ),
                );
            } else {
                setAlertsSound(alertsSoundsList);
            }
        }
    }, [query]);

    useEffect(
        () => {
            if (played) {
                player.src = played.stickerUrl as string;
                player.loop = true;
                void player.play();
            } else {
                player.pause();
            }
        },
        [played],
    );

    return {
        isLoading,
        alertsSounds,
        played,
        onQueryChange,
        onPlayedChange,
    };
};
