import { useAlertsSettingsType } from '@views/pages/StreamerSettings/components/AlertSettings/AlertsSettingsType/hooks';

interface IUsePresetParams {
    alertsSettingsType: ReturnType<typeof useAlertsSettingsType>;
}

export const usePreset = (params: IUsePresetParams) => {
    const {
        alertsSettingsType: {
            // alertStyle,
            presets = [],
            selectedPresetId,
        },
    } = params;
    const selectedPreset = presets.find((item) => item.id === selectedPresetId);
    const isSaveDisabled = !selectedPreset || selectedPreset.isSystem;

    return {
        selectedPreset,
        isSaveDisabled,
    };
};
