import cn from 'classnames';
import React, { useEffect, useRef } from 'react';

import { FillType, IStreamojiStyle, StreamojiType, StrokeSize } from '@typings';
import { VISUAL_EFFECT_SPEED_MAP } from '@views/pages/StreamerSettings/components/AlertSettings/const';

import s from './Image.scss';
import { EAnimationState } from '../../enums';

interface IImageProps {
    className?: string;
    avatarStyle: IStreamojiStyle;
    imageUrl: string;
    animationState?: EAnimationState;
}

const STROKE_SIZE_MAP = {
    [StrokeSize.NONE]: 0,
    [StrokeSize.S]: 2,
    [StrokeSize.M]: 4,
    [StrokeSize.L]: 6,
};

export const Image = (props: IImageProps) => {
    const {
        className,
        avatarStyle: {
            type,
            size,
            background,
            stroke,
            effects,
        },
        imageUrl,
        animationState,
    } = props;
    const bgStyles = type === StreamojiType.ROUND || type === StreamojiType.SQUARE
        ? (
            stroke.fill.fillType === FillType.FLAT
                ? {
                    backgroundColor: stroke.fill.primaryColor,
                    padding: `${STROKE_SIZE_MAP[stroke.size]}px`,
                }
                : {
                    background: `linear-gradient(${stroke.fill.primaryColor}, ${stroke.fill.secondaryColor})`,
                    padding: `${STROKE_SIZE_MAP[stroke.size]}px`,
                }
        )
        : {};
    const avatarStyles = (type === StreamojiType.ROUND || type === StreamojiType.SQUARE)
        ? (
            background.fillType === FillType.GRADIENT
                ? { background: `linear-gradient(${background.primaryColor}, ${background.secondaryColor})` }
                : { backgroundColor: background.primaryColor }
        )
        : {};
    const duration = animationState === EAnimationState.NONE
        ? 0
        : (
            animationState === EAnimationState.IN
                ? VISUAL_EFFECT_SPEED_MAP[effects.in.speed]
                : VISUAL_EFFECT_SPEED_MAP[effects.out.speed]
        );
    const rootRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        rootRef?.current?.style?.setProperty('--animate-duration', `${duration}s`);
    }, [duration]);

    return (
        <div
            className={
                cn(
                    s.root,
                    s[`size-${size}`],
                    s[`type-${type}`],
                    {
                        'animate__animated': animationState !== EAnimationState.NONE,
                        [`animate__${effects.in.effect}`]: animationState === EAnimationState.IN,
                        [`animate__${effects.out.effect}`]: animationState === EAnimationState.OUT,
                    },
                    className,
                )
            }
            style={bgStyles}
            ref={rootRef}
        >
            <div
                className={s.avatar}
                style={avatarStyles}
            >
                <div
                    className={s.avatarPicture}
                    style={{ backgroundImage: `url("${imageUrl}")` }}
                />
            </div>
        </div>
    );
};
