import { AutoTextSize } from 'auto-text-size';
import cn from 'classnames';
import React, { useEffect, useRef } from 'react';

import { FillType, ITextStyle, StrokeSize } from '@typings';
import { VISUAL_EFFECT_SPEED_MAP } from '@views/pages/StreamerSettings/components/AlertSettings/const';

import s from './Text.scss';
import { EAnimationState } from '../../enums';
import { getFillTextStyles } from '../../utils/getFillTextStyles';
import { getOutlinedTextStyles } from '../../utils/getOutlinedTextStyles';
import { renderTemplatedText } from '../../utils/renderTemplatedText';

interface ITitleProps {
    className?: string;
    vars: Record<string, string>;
    textStyle: ITextStyle;
    animationState?: EAnimationState;
}

const MIN_FONT_SIZE = 10;
const MAX_LENGTH = 200;

export const Text = (props: ITitleProps) => {
    const {
        className,
        vars,
        textStyle,
        animationState,
    } = props;
    const varsStyles = getFillTextStyles(textStyle.variablesFill);
    const fillStyles = getFillTextStyles(textStyle.fill);
    const outlinedStyles = getOutlinedTextStyles({
        stroke: textStyle.stroke,
    });
    const duration = animationState === EAnimationState.NONE
        ? 0
        : (
            animationState === EAnimationState.IN
                ? VISUAL_EFFECT_SPEED_MAP[textStyle.effects.in.speed]
                : VISUAL_EFFECT_SPEED_MAP[textStyle.effects.out.speed]
        );
    const rootRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        rootRef?.current?.style?.setProperty('--animate-duration', `${duration}s`);
    }, [duration]);

    return (
        <div
            className={cn(
                s.root,
                s[`align-${textStyle.align}`],
                {
                    'animate__animated': animationState !== EAnimationState.NONE,
                    [`animate__${textStyle.effects.in.effect}`]: animationState === EAnimationState.IN,
                    [`animate__${textStyle.effects.out.effect}`]: animationState === EAnimationState.OUT,
                },
                className,
            )}
            ref={rootRef}
        >
            <div
                className={cn(s.text, { [s.textGradient]: textStyle.fill.fillType === FillType.GRADIENT })}
                style={{
                    fontFamily: textStyle.fontFamily,
                    ...fillStyles,
                }}
            >
                <AutoTextSize
                    mode="multiline"
                    minFontSizePx={MIN_FONT_SIZE}
                    maxFontSizePx={Number(textStyle.fontSize)}
                >
                    {renderTemplatedText({
                        template: textStyle.text,
                        vars,
                        varsStyles,
                        varsClassName: cn(s.variable, { [s.variableGradient]: textStyle.variablesFill.fillType === FillType.GRADIENT }),
                        maxLength: MAX_LENGTH,
                    })}
                </AutoTextSize>
            </div>

            {textStyle.stroke.size !== StrokeSize.NONE && (
                <div
                    className={s.stroke}
                    style={{
                        ...outlinedStyles,
                        fontFamily: textStyle.fontFamily,
                    }}
                >
                    <AutoTextSize
                        mode="multiline"
                        minFontSizePx={MIN_FONT_SIZE}
                        maxFontSizePx={Number(textStyle.fontSize)}
                    >
                        {renderTemplatedText({
                            template: textStyle.text,
                            vars,
                            varsStyles,
                            varsClassName: cn(s.variable, { [s.variableGradient]: textStyle.variablesFill.fillType === FillType.GRADIENT }),
                            maxLength: MAX_LENGTH,
                        })}
                    </AutoTextSize>
                </div>
            )}

            {textStyle.textShadow.enabled && (
                <div
                    className={s.textShadow}
                    style={{
                        top: `${textStyle.textShadow.indent}px`,
                        color: textStyle.textShadow.fill.primaryColor,
                        fontFamily: textStyle.fontFamily,
                        filter: `blur(${textStyle.textShadow.blur}px)`,
                    }}
                >
                    <AutoTextSize
                        mode="multiline"
                        minFontSizePx={MIN_FONT_SIZE}
                        maxFontSizePx={Number(textStyle.fontSize)}
                    >
                        {renderTemplatedText({
                            template: textStyle.text,
                            vars,
                            varsClassName: cn(s.variable, { [s.variableGradient]: textStyle.variablesFill.fillType === FillType.GRADIENT }),
                            maxLength: MAX_LENGTH,
                        })}
                    </AutoTextSize>
                </div>
            )}
        </div>
    );
};
