import {
    IChannelStickersLoadParams,
    IFavoriteStickersLoadParams, IGame,
    ILoadPage, ISendMemeCannonStickerParams, ISendRandomStickerParams, ISendStickerParams,
    ISticker, IStickerCreationParams,
    IStickerCustomSettingsUpdateParams, ITag,
    TLoadPageUsing, TMemeAlertsEvent,
} from '@typings';

import { baseApi } from './base.api';

export const stickersApi = baseApi
    .enhanceEndpoints({
        addTagTypes: [
            'Stickers_PersonalAreaCatalogue',
            'Stickers_PersonalAreaFavorites',
            'Stickers_PersonalAreaMine',
            'Stickers_PersonalAreaChannel',
            'Stickers_PersonalAreaSearch',

            'Stickers_StreamerAreaCatalogue',
            'Stickers_StreamerAreaPopular',
            'Stickers_StreamerAreaChannel',
            'Stickers_StreamerAreaLastSent',
            'Stickers_StreamerAreaFavorites',
            'Stickers_StreamerAreaMine',
            'Stickers_StreamerAreaSearch',
        ],
    })
    .injectEndpoints({
        endpoints: (build) => ({
            /** Loads stickers' page of common catalog */
            loadPagePersonalAreaCatalogue: build.query<Array<ISticker>, ILoadPage>({
                query: (params: ILoadPage) => ({
                    url: 'sticker/personal-area/catalogue',
                    method: 'POST',
                    body: params,
                }),
                providesTags: ['Stickers_PersonalAreaCatalogue'],
            }),

            /** Loads stickers' page of streamer's favorite stickers */
            loadPagePersonalAreaFavorites: build.query<Array<ISticker>, TLoadPageUsing<IFavoriteStickersLoadParams>>({
                query: (params: TLoadPageUsing<IFavoriteStickersLoadParams>) => ({
                    url: 'sticker/personal-area/favorites',
                    method: 'POST',
                    body: params,
                }),
                providesTags: ['Stickers_PersonalAreaFavorites'],
            }),

            /** Loads a page of stickers that are added to the channel */
            loadPagePersonalAreaChannel: build.query<Array<ISticker>, TLoadPageUsing<IChannelStickersLoadParams>>({
                query: (params: TLoadPageUsing<IChannelStickersLoadParams>) => ({
                    url: 'sticker/personal-area/mychannel',
                    method: 'POST',
                    body: params,
                }),
                providesTags: ['Stickers_PersonalAreaChannel'],
            }),

            /** Loads a page of stickers that were created by the streamer */
            loadPagePersonalAreaMine: build.query<Array<ISticker>, ILoadPage>({
                query: (params: ILoadPage) => ({
                    url: 'sticker/personal-area/mine',
                    method: 'POST',
                    body: params,
                }),
                providesTags: ['Stickers_PersonalAreaMine'],
            }),

            /** Performs search throw sticker catalogues using search query */
            loadPagePersonalAreaSearch: build.query<ISticker[], TLoadPageUsing<{searchQuery?: string}>>({
                query: (params: TLoadPageUsing<{searchQuery?: string}>) => ({
                    url: 'sticker/personal-area/search',
                    method: 'POST',
                    body: params,
                }),
                providesTags: ['Stickers_PersonalAreaSearch'],
            }),

            /** Load sticker by id */
            loadSticker: build.query<ISticker, string>({
                query: (stickerId: string) => ({
                    url: `sticker/${stickerId}`,
                    method: 'GET',
                }),
            }),

            /** Load stickers to select sounds in alert settings */
            loadAlertsSounds: build.query<ISticker[], void>({
                query: () => ({
                    url: 'sticker/alerts-sounds',
                    method: 'GET',
                }),
            }),

            /** Adds a sticker to the favorites */
            addStickerToFavorites: build.mutation<ISticker, string>({
                query: (stickerId: string) => ({
                    url: `sticker/${stickerId}/favorite`,
                    method: 'POST',
                }),
            }),

            /** Removes a sticker to the favorites */
            removeStickerFromFavorites: build.mutation<ISticker, string>({
                query: (stickerId: string) => ({
                    url: `sticker/${stickerId}/favorite`,
                    method: 'DELETE',
                }),
            }),

            /** Adds a sticker to the channel */
            addStickerToChannel: build.mutation<ISticker, string>({
                query: (stickerId: string) => ({
                    url: `sticker/${stickerId}/channel`,
                    method: 'POST',
                }),
            }),

            /** Removes a sticker to the channel */
            removeStickerFromChannel: build.mutation<ISticker, string>({
                query: (stickerId: string) => ({
                    url: `sticker/${stickerId}/channel`,
                    method: 'DELETE',
                }),
            }),

            /** Update custom settings of a sticker */
            updateStickerCustomSettings: build.mutation<ISticker, IStickerCustomSettingsUpdateParams>({
                query: (params: IStickerCustomSettingsUpdateParams) => ({
                    url: `sticker/${params.stickerId}/custom-settings`,
                    method: 'POST',
                    body: params.stickerCustomSettings,
                }),
            }),

            createSticker: build.mutation<ISticker, IStickerCreationParams>({
                query: (params: IStickerCreationParams) => ({
                    url: 'sticker',
                    method: 'POST',
                    body: params,
                }),
            }),

            /** Delete a sticker at all */
            deleteSticker: build.mutation<ISticker, string>({
                query: (stickerId: string) => ({
                    url: `sticker/${stickerId}`,
                    method: 'DELETE',
                }),
            }),

            /** Sends sticker to the streamer's channel */
            sendSticker: build.mutation<boolean, ISendStickerParams>({
                query: (params: ISendStickerParams) => ({
                    url: `sticker/send`,
                    method: 'POST',
                    body: params,
                }),
            }),

            sendRandomSticker: build.mutation<void, ISendRandomStickerParams>({
                query: (params: ISendRandomStickerParams) => ({
                    url: `sticker/send-random`,
                    method: 'POST',
                    body: params,
                }),
            }),

            sendMemeCannonSticker: build.mutation<boolean, ISendMemeCannonStickerParams>({
                query: (params: ISendMemeCannonStickerParams) => ({
                    url: `sticker/send-meme-cannon`,
                    method: 'POST',
                    body: params,
                }),
            }),

            // ====
            loadPageStreamerAreaCatalogue: build.query<Array<ISticker>, TLoadPageUsing<{ streamerId: string }>>({
                query: (params: TLoadPageUsing<{ streamerId: string }>) => ({
                    url: 'sticker/streamer-area/catalogue',
                    method: 'POST',
                    body: { ...params, pageSize: params.limit },
                }),
                providesTags: ['Stickers_StreamerAreaCatalogue'],
            }),
            loadPageStreamerAreaPopular: build.query<Array<ISticker>, TLoadPageUsing<{ streamerId: string }>>({
                query: (params: TLoadPageUsing<{ streamerId: string }>) => ({
                    url: `sticker/streamer-area/last-sent-by-all`,
                    method: 'POST',
                    body: { ...params, pageSize: params.limit },
                }),
                providesTags: ['Stickers_StreamerAreaLastSent'],
            }),
            loadPageStreamerAreaChannel: build.query<Array<ISticker>, TLoadPageUsing<{ streamerId: string }>>({
                query: (params: TLoadPageUsing<{ streamerId: string }>) => ({
                    url: `sticker/streamer-area/channel`,
                    method: 'POST',
                    body: { ...params, pageSize: params.limit },
                }),
                providesTags: ['Stickers_StreamerAreaChannel'],
            }),
            loadPageStreamerAreaLastSent: build.query<Array<ISticker>, TLoadPageUsing<{ streamerId: string }>>({
                query: (params: TLoadPageUsing<{ streamerId: string }>) => ({
                    url: `sticker/streamer-area/last-sent`,
                    method: 'POST',
                    body: { ...params, pageSize: params.limit },
                }),
                providesTags: ['Stickers_StreamerAreaLastSent'],
            }),
            loadPageStreamerAreaUserFavorites: build.query<Array<ISticker>, TLoadPageUsing<{ streamerId: string }>>({
                query: (params: TLoadPageUsing<{ streamerId: string }>) => ({
                    url: `sticker/streamer-area/user-favorites`,
                    method: 'POST',
                    body: { ...params, pageSize: params.limit },
                }),
                providesTags: ['Stickers_StreamerAreaFavorites'],
            }),
            loadPageStreamerAreaMine: build.query<Array<ISticker>, TLoadPageUsing<{ streamerId: string }>>({
                query: (params: TLoadPageUsing<{ streamerId: string }>) => ({
                    url: `sticker/streamer-area/mine`,
                    method: 'POST',
                    body: { ...params, pageSize: params.limit },
                }),
                providesTags: ['Stickers_StreamerAreaMine'],
            }),
            loadPageStreamerAreaThematicSet: build.query<Array<ISticker>, TLoadPageUsing<{ streamerId: string, thematicSetId: string }>>({
                query: (params: TLoadPageUsing<{ streamerId: string, thematicSetId: string }>) => ({
                    url: `thematic-set/${params.thematicSetId}`,
                    method: 'POST',
                    body: { ...params, pageSize: params.limit },
                }),
            }),

            loadPageStreamerAreaSearch: build.query<Array<ISticker>, TLoadPageUsing<{ streamerId: string, searchQuery: string }>>({
                query: (params: TLoadPageUsing<{ streamerId: string, searchQuery: string }>) => ({
                    url: 'sticker/streamer-area/search',
                    method: 'POST',
                    body: params,
                }),
                providesTags: ['Stickers_StreamerAreaSearch'],
            }),

            replaySticker: build.query<boolean, string>({
                query: (sendStickerEventId: string) => ({
                    url: `sticker/replay`,
                    method: 'POST',
                    body: {
                        sendStickerEventId,
                    },
                }),
            }),

            toggleStickerDisableState: build.query<TMemeAlertsEvent, string>({
                query: (eventId: string) => ({
                    url: `sticker/toggle-disable-state`,
                    method: 'POST',
                    body: {
                        eventId,
                    },
                }),
            }),

            findTag: build.query<ITag[], string>({
                query: (tag: string) => ({
                    url: `tag/find/${tag}`,
                    method: 'POST',
                }),
            }),

            findGame: build.query<IGame[], string>({
                query: (query: string) => ({
                    url: `game/find/${query}`,
                    method: 'POST',
                }),
            }),
        }),
    });
