import { Row } from '@hyperclap/ui';
import cn from 'classnames';
import React from 'react';

import { IconCheckLine, IconPauseLine, IconPlayLine } from '@assets/images/svg';
import { ISticker } from '@typings';

import s from './SoundSelectorItem.scss';

interface ISoundSelectorItemProps {
    sticker: ISticker;
    selected?: boolean;
    played?: boolean;
    onSelect(): void;
    onPlay(): void;
    onStop(): void;
}

export const SoundSelectorItem = (props: ISoundSelectorItemProps) => {
    const {
        sticker,
        selected,
        played,
        onSelect,
        onPlay,
        onStop,
    } = props;

    return (
        <Row className={cn(s.root, { [s.selected]: selected })}>
            {played
                ? (
                    <IconPauseLine
                        className={s.playIcon}
                        onClick={onStop}
                    />
                )
                : (
                    <IconPlayLine
                        className={s.playIcon}
                        onClick={onPlay}
                    />
                )
            }

            <div className={s.name}>{sticker.name}</div>

            <div
                className={s.check}
                onClick={onSelect}
            >
                <IconCheckLine className={s.checkIcon}/>
            </div>
        </Row>
    );
};
