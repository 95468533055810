import { Column, EColumnAlignment, Row } from '@hyperclap/ui';
import cn from 'classnames';
import React from 'react';

import s from './IconRadioGroup.scss';

export interface IIconRadioGroupItem {
    title?: string;
    icon: React.FC<{className?: string}>;
    value: string;
    className?: string;
}

 interface IIconRadioGroupProps {
    items: IIconRadioGroupItem[];
    value: string;
    classNames?: {
        root?: string;
        item?: string;
        itemActive?: string;
        icon?: string;
        title?: string;
    };
    onChange(value: string): void;
}

export const IconRadioGroup = (props: IIconRadioGroupProps) => {
    const {
        items,
        value,
        classNames = {},
        onChange,
    } = props;

    const handleItemClick = (item: IIconRadioGroupItem) => () => {
        onChange(item.value);
    };

    return (
        <Row className={cn(s.root, classNames.root)}>
            {items.map((item, index) => {
                const {
                    title,
                    icon: Icon,
                } = item;

                return (
                    <Column
                        key={index}
                        className={
                            cn(
                                s.item,
                                {
                                    [s.itemActive]: value === item.value,
                                    [classNames.itemActive || '']: value === item.value,
                                },
                                classNames.item,
                                item.className,
                            )
                        }
                        alignment={EColumnAlignment.CENTER}
                        onClick={handleItemClick(item)}
                    >
                        <div className={s.itemIconWrapper}>
                            <Icon className={cn(s.itemIcon, classNames.icon)}/>
                        </div>
                        <div className={cn(s.itemTitle, classNames.title)}>{title}</div>
                    </Column>
                );
            })}
        </Row>
    );
};
