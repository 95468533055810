import { AutoTextSize } from 'auto-text-size';
import cn from 'classnames';
import React, { useEffect, useRef } from 'react';

import { FillType, ITitleStyle, StrokeSize } from '@typings';
import { VISUAL_EFFECT_SPEED_MAP } from '@views/pages/StreamerSettings/components/AlertSettings/const';

import s from './Title.scss';
import { EAnimationState } from '../../enums';
import { getFillTextStyles } from '../../utils/getFillTextStyles';
import { getOutlinedTextStyles } from '../../utils/getOutlinedTextStyles';
import { renderTemplatedText } from '../../utils/renderTemplatedText';

interface ITitleProps {
    className?: string;
    vars: Record<string, string>;
    titleStyle: ITitleStyle;
    animationState?: EAnimationState;
}

const MIN_FONT_SIZE = 10;

export const Title = (props: ITitleProps) => {
    const {
        className,
        vars,
        titleStyle,
        animationState,
    } = props;
    const varsStyles = getFillTextStyles(titleStyle.variablesFill);
    const fillStyles = getFillTextStyles(titleStyle.fill);
    const outlinedStyles = getOutlinedTextStyles({
        stroke: titleStyle.stroke,
    });
    const duration = animationState === EAnimationState.NONE
        ? 0
        : (
            animationState === EAnimationState.IN
                ? VISUAL_EFFECT_SPEED_MAP[titleStyle.effects.in.speed]
                : VISUAL_EFFECT_SPEED_MAP[titleStyle.effects.out.speed]
        );
    const rootRef = useRef<HTMLDivElement>(null);

    useEffect(() => {
        rootRef?.current?.style?.setProperty('--animate-duration', `${duration}s`);
    }, [duration]);

    return (
        <div
            className={cn(
                s.root,
                s[`align-${titleStyle.align}`],
                {
                    'animate__animated': animationState !== EAnimationState.NONE,
                    [`animate__${titleStyle.effects.in.effect}`]: animationState === EAnimationState.IN,
                    [`animate__${titleStyle.effects.out.effect}`]: animationState === EAnimationState.OUT,
                },
                className,
            )}
            style={{
                fontFamily: `"${titleStyle.fontFamily}"`,
            }}
            ref={rootRef}
        >
            <div
                className={cn(s.text, { [s.textGradient]: titleStyle.fill.fillType === FillType.GRADIENT })}
                style={{
                    ...fillStyles,
                }}
            >
                <AutoTextSize
                    mode="oneline"
                    minFontSizePx={MIN_FONT_SIZE}
                    maxFontSizePx={Number(titleStyle.fontSize)}
                >
                    {renderTemplatedText({
                        template: titleStyle.text,
                        vars,
                        varsStyles,
                        varsClassName: cn(
                            s.variable,
                            { [s.variableGradient]: titleStyle.variablesFill.fillType === FillType.GRADIENT },
                        ),
                    })}
                </AutoTextSize>
            </div>

            {titleStyle.stroke.size !== StrokeSize.NONE && (
                <div
                    className={s.stroke}
                    style={{
                        ...outlinedStyles,
                    }}
                >
                    <AutoTextSize
                        mode="oneline"
                        minFontSizePx={MIN_FONT_SIZE}
                        maxFontSizePx={Number(titleStyle.fontSize)}
                    >
                        {renderTemplatedText({
                            template: titleStyle.text,
                            vars,
                            varsStyles,
                            varsClassName: s.variable,
                        })}
                    </AutoTextSize>
                </div>
            )}

            {titleStyle.textShadow.enabled && (
                <div
                    className={s.textShadow}
                    style={{
                        top: `${titleStyle.textShadow.indent}px`,
                        color: titleStyle.textShadow.fill.primaryColor,
                        fontFamily: `"${titleStyle.fontFamily}"`,
                        filter: `blur(${titleStyle.textShadow.blur}px)`,
                    }}
                >
                    <AutoTextSize
                        mode="oneline"
                        minFontSizePx={MIN_FONT_SIZE}
                        maxFontSizePx={Number(titleStyle.fontSize)}
                    >
                        {renderTemplatedText({
                            template: titleStyle.text,
                            vars,
                        })}
                    </AutoTextSize>
                </div>
            )}
        </div>
    );
};
