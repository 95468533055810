import { IMemePartyState, ISticker, TMemeAlertsEvent } from '@typings';

/** Websocket messages types */
export enum WSMessageType {
    /** Generic websocket message that contains free-form data as a payload */
    GENERIC = 1,
    /** Message informs that the sticker was received (used by OBS Layer subsystem) */
    STICKER_RECEIVED = 2,
    /** Message contains command for OBS layer to do something, refresh settings for example */
    OBS_COMMAND = 3,
    /** Message contains data of an internal event is generated by server side (use by Events page) */
    EVENT_CREATED = 4,
    /** Server side informs that current user balance was increased */
    INCREMENT_BALANCE = 5,
    /** Server side informs that current user balance was decreased */
    DECREMENT_BALANCE = 6,
    /** Server side informs that OBS Widget should update its state */
    WIDGET_STATE = 7,
    /** Message informs there are unread events on Events page */
    UNREAD_EVENTS = 8,
    /** Message informs that the fullscreen sticker was received (used by OBS Layer subsystem) */
    FULLSCREEN_STICKER_RECEIVED = 9,
    /** Message informs that the meme-cannon sticker was received (used by OBS Layer subsystem) */
    MEME_CANNON_STICKER_RECEIVED = 10,
    /** Message informs that the user's mute state on the streamer page is changed */
    MUTE_STATE_CHANGED = 11,
    /** Message informs that the user's mute state on the streamer page is changed */
    BAN_STATE_CHANGED = 12,

}

export enum ObsCommand {
    TOGGLE_SETTINGS_OVERLAY = 'toggle-setting-overlay',
    REFETCH_SETTINGS = 'refetch-settings',
}

export enum ObsWidgetType {
    MEME_PARTY = 'meme-party-widget'
}

/** Type of data field in the websocket message data-field */
type TGenericData = Record<string, unknown>;

/** Abstract Websocket message definition */
export type TAbstractWSMessage<T extends WSMessageType, D extends TGenericData> = {
    /** Message timestamp */
    timestamp: number;
    /** Message type */
    type: T;
    /** Message payload */
    data: D;
}

type TObsWidgetState<W, T> = { widgetType: W; widgetData: T };
type TMemePartyState = { [P in keyof IMemePartyState]: IMemePartyState[P] }

type TStickerReceivedPayload = { sticker: ISticker, isSoundOnly: boolean, message?: string }
type TObsCommandPayload = { command: ObsCommand; parameters: TGenericData }
type TBalancePayload = { balance: number }
type TUnreadEventsPayload = { state: boolean }
type TEventPayload = { eventId: string; event: TMemeAlertsEvent }
type TMemeCannonStickerReceivedPayload = { sticker: ISticker; count: number, isSoundOnly: boolean, message?: string }
type TObsWidgetStatePayload<W extends ObsWidgetType, T extends TGenericData> = { state: TObsWidgetState<W, T> }
type TMuteStateChangedPayload = { muted: boolean }
type TBanStateChangedPayload = { banned: boolean }

interface GenericWSMessage extends TAbstractWSMessage<
    WSMessageType.GENERIC,
    TGenericData
> {}

interface StickerReceivedWSMessage extends TAbstractWSMessage<
    WSMessageType.STICKER_RECEIVED,
    TStickerReceivedPayload
> {}

interface ObsCommandWSMessage extends TAbstractWSMessage<
    WSMessageType.OBS_COMMAND,
    TObsCommandPayload
> {}

interface IncrementBalanceWSMessage extends TAbstractWSMessage<
    WSMessageType.INCREMENT_BALANCE,
    TBalancePayload
> {}

interface DecrementBalanceWSMessage extends TAbstractWSMessage<
    WSMessageType.DECREMENT_BALANCE,
    TBalancePayload
> {}

interface UnreadEventsWSMessage extends TAbstractWSMessage<
    WSMessageType.UNREAD_EVENTS,
    TUnreadEventsPayload
> {}

interface EventWSMessage extends TAbstractWSMessage<
    WSMessageType.EVENT_CREATED,
    TEventPayload
> {}

interface FullscreenStickerReceivedWSMessage extends TAbstractWSMessage<
    WSMessageType.FULLSCREEN_STICKER_RECEIVED,
    TStickerReceivedPayload
> {}

interface MemeCannonStickerReceivedWSMessage extends TAbstractWSMessage<
    WSMessageType.MEME_CANNON_STICKER_RECEIVED,
    TMemeCannonStickerReceivedPayload
> {}

interface MemePartyWidgetStateChangedWSMessage extends TAbstractWSMessage<
    WSMessageType.WIDGET_STATE,
    TObsWidgetStatePayload<ObsWidgetType.MEME_PARTY, TMemePartyState>
> {}

interface MuteStateChangedWSMessage extends TAbstractWSMessage<
    WSMessageType.MUTE_STATE_CHANGED,
    TMuteStateChangedPayload
> {}

interface BanStateChangedWSMessage extends TAbstractWSMessage<
    WSMessageType.BAN_STATE_CHANGED,
    TBanStateChangedPayload
> {}

export type TWSMessage =
    GenericWSMessage |
    StickerReceivedWSMessage |
    ObsCommandWSMessage |
    IncrementBalanceWSMessage |
    DecrementBalanceWSMessage |
    UnreadEventsWSMessage |
    EventWSMessage |
    FullscreenStickerReceivedWSMessage |
    MemeCannonStickerReceivedWSMessage |
    MemePartyWidgetStateChangedWSMessage |
    MuteStateChangedWSMessage |
    BanStateChangedWSMessage;
