import { Column, EColumnAlignment, Row } from '@hyperclap/ui';
import cn from 'classnames';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { AlertLayout } from '@typings';

import { IconHorizontal, IconVertical } from './assets';
import s from './LayoutSwitcher.scss';


interface ILayoutSwitcherProps {
    layout: AlertLayout;
    onChange(value: AlertLayout): void;
}

export const LayoutSwitcher = (props: ILayoutSwitcherProps) => {
    const {
        layout,
        onChange,
    } = props;
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`streamer-settings.alerts-type.${id}`);

    const handleLayoutClick = (value: AlertLayout) => () => onChange(value);

    return (
        <Row>
            <Column
                alignment={EColumnAlignment.CENTER}
                className={
                    cn(
                        s.item,
                        s.itemHorizontal,
                        { [s.itemActive]: layout === AlertLayout.HORIZONTAL },
                    )
                }
                onClick={handleLayoutClick(AlertLayout.HORIZONTAL)}
            >
                <div className={s.itemContent}>
                    <IconHorizontal className={s.itemIcon}/>
                    <div className={s.itemTitle}>{t('horizontal')}</div>
                </div>
            </Column>
            <Column
                alignment={EColumnAlignment.CENTER}
                className={
                    cn(
                        s.item,
                        s.itemVertical,
                        { [s.itemActive]: layout === AlertLayout.VERTICAL },
                    )
                }
                onClick={handleLayoutClick(AlertLayout.VERTICAL)}
            >
                <div className={s.itemContent}>
                    <IconVertical className={s.itemIcon}/>
                    <div className={s.itemTitle}>{t('vertical')}</div>
                </div>
            </Column>
        </Row>
    );
};
