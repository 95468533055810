import { Button, ERowAlignment, ERowJustification, ISelectOption, Row, Select, useModals } from '@hyperclap/ui';
import cn from 'classnames';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { IconPlusLine, IconSaveLine } from '@assets/images/svg';

import { CreateForm } from './components/CreateForm';
import { usePreset } from './hooks';
import s from './Preset.scss';
import { Field } from '../../components/Elements/components/FormSwitcher/components/Field';
import { useAlertsSettingsType } from '../../hooks';

interface IPresetsProps {
    alertsSettingsType: ReturnType<typeof useAlertsSettingsType>;
}

export const Preset = (props: IPresetsProps) => {
    const { alertsSettingsType } = props;
    const {
        alertStyle,
        presets = [],
        selectedPresetId,
        onSelectedPresetChange,
    } = alertsSettingsType;
    const { isSaveDisabled } = usePreset({ alertsSettingsType });
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`streamer-settings.alerts-type.${id}`);
    const { showModal } = useModals();

    const handlePresetChange = (selection?: ISelectOption) => {
        onSelectedPresetChange(selection?.value as string);
    };

    const handleCreateClick = () => {
        showModal({
            content: (
                <CreateForm
                    alertStyle={alertStyle}
                />
            ),
            options: {},
        });
    };

    return (
        <Row
            className={s.root}
            justification={ERowJustification.SPACE_BETWEEN}
            alignment={ERowAlignment.BOTTOM}
        >
            <Row alignment={ERowAlignment.BOTTOM}>
                <Field
                    label={t('forms.style')}
                    className={s.select}
                    content={(
                        <Select
                            placeholder={t('notSelected')}
                            options={presets?.map((item) => ({
                                caption: item.name,
                                value: item.id,
                                selected: item.id === selectedPresetId,
                            }))}
                            onSelectionChanged={handlePresetChange}
                        />
                    )}
                />

                <Button
                    className={cn(s.button, s.buttonDisabled)}
                    disabled={isSaveDisabled}
                    caption={(
                        <IconSaveLine className={s.buttonIcon}/>
                    )}
                />

                <Button
                    className={s.button}
                    caption={(
                        <IconPlusLine className={s.buttonIcon}/>
                    )}
                    onClick={handleCreateClick}
                />
            </Row>
        </Row>
    );
};
