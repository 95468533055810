import { Column, Loader, TextField, useModals } from '@hyperclap/ui';
import React from 'react';
import { useTranslation } from 'react-i18next';

import { IconCloseLine } from '@assets/images/svg';
import { ISticker } from '@typings';

import { SoundSelectorItem } from './components/SoundSelectorItem';
import { useSoundSelector } from './hooks';
import s from './SoundSelector.scss';

interface ISoundSelectorProps {
    value?: ISticker;
    onChange(value: ISticker): void;
}

export const SoundSelector = (props: ISoundSelectorProps) => {
    const {
        value,
        onChange,
    } = props;
    const {
        alertsSounds,
        isLoading,
        played,
        onQueryChange,
        onPlayedChange,
    } = useSoundSelector();
    const { closeModal } = useModals();
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`streamer-settings.alerts-type.soundSettings.${id}`);

    const handleCloseClick = () => closeModal();

    const handleItemSelect = (item: ISticker) => () => {
        onChange(item);
        closeModal();
    };

    const handleItemPlay = (item: ISticker) => () => onPlayedChange(item);

    const handleItemStop = () => onPlayedChange();

    return (
        <div className={s.root}>
            <IconCloseLine
                className={s.close}
                onClick={handleCloseClick}
            />

            {isLoading
                ? <Loader/>
                : (
                    <>
                        <TextField
                            className={s.input}
                            placeholder={t('search')}
                            onChanged={onQueryChange}
                        />

                        <Column className={s.list}>
                            {alertsSounds?.map((item) => (
                                <SoundSelectorItem
                                    key={item.id}
                                    sticker={item}
                                    selected={item.id === value?.id}
                                    played={item.id === played?.id}
                                    onSelect={handleItemSelect(item)}
                                    onPlay={handleItemPlay(item)}
                                    onStop={handleItemStop}
                                />
                            ))}
                        </Column>
                    </>
                )
            }
        </div>
    );
};
