import { EComponentSize, Label } from '@hyperclap/ui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { IconFace } from '@assets/images/svg';

import s from './CategoryList.scss';
import { ICategoryListProps } from './types';
import { useCategoriesInfo } from '../../hooks';

export const CategoryList = (props: ICategoryListProps) => {
    const {
        categories,
        onItemClick,
        onFaceClick,
    } = props;
    const { t: translation } = useTranslation();
    const { categoriesInfo } = useCategoriesInfo();

    const t = (id: string) => translation(`avatar-editor.CategoryList.${id}`);

    return (
        <div className={s.root}>
            <Label padding={{ top: 20 }} caption={t('title')} size={EComponentSize.MEDIUM}/>

            <div className={s.list}>
                <div
                    className={s.item}
                    onClick={onFaceClick}
                >
                    <IconFace className={s.icon}/>
                    {t('face')}
                </div>

                {categories
                    .filter((item) => !item.isNotVariable)
                    .map((item) => {
                        // const Icon = MAP_CATEGORY_ID_TO_CLASSNAME[item.id];
                        const { Icon, title } = categoriesInfo[item.id];

                        return (
                            <div
                                key={item.id}
                                className={s.item}
                                onClick={() => onItemClick((item.id))}
                            >
                                {Icon && (
                                    <Icon className={s.icon}/>
                                )}
                                {title}
                            </div>
                        );
                    })}
            </div>
        </div>
    );
};

