import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationType } from '@contexts';
import { useApi, useNotifications } from '@hooks';
import {
    AlertLayout,
    AlertPosition,
    AlertSize,
    AlertType,
    AlignText,
    ETextBoxBorderRadius,
    FillType,
    FontSize,
    IAlertTypeItem,
    IFillStyle, ISticker,
    IStreamojiStyle,
    IStrokeStyle,
    ITextBoxStyle,
    ITextShadowStyle,
    ITextStyle,
    ITitleStyle,
    IVisualEffects,
    StreamojiPosition,
    StreamojiSize,
    StreamojiType,
    StrokeSize,
    TextPosition,
    TextShadowBlur,
    TextShadowIndent,
    VisualEffectSpeed,
} from '@typings';

import {
    DEFAULT_ALERT_STYLE,
    VISUAL_EFFECTS_IN,
    VISUAL_EFFECTS_OUT,
    DEFAULT_GRADIENT_COLOR_START,
    DEFAULT_GRADIENT_COLOR_END,
} from '../../../const';
import { EDuration, EFontFamily, EMargin, EOpacity } from '../../enums';

interface IUseAlertsSettingsTypeParams {
    type: AlertType;
}

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const useAlertsSettingsType = (params: IUseAlertsSettingsTypeParams) => {
    const { type } = params;
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`streamer-settings.alerts-type.forms.${id}`);

    const { notify } = useNotifications();
    const {
        alerts: {
            useLoadAlertsQuery,
            useLoadAlertStylesQuery,
            useUpdateAlertTypeMutation,
            useCreateAlertTypeMutation,
            // useUpdateAlertStyleMutation,
        },
        stickers: {
            useLazyLoadStickerQuery,
        },
    } = useApi();
    const {
        data: presets,
        // isSuccess: isAlertStylesLoadSuccess,
    } = useLoadAlertStylesQuery();
    const {
        data: alertTypes,
        isSuccess: isAlertsLoadSuccess,
    } = useLoadAlertsQuery();
    // const [updateAlertStyle] = useUpdateAlertStyleMutation();
    const [updateAlertType] = useUpdateAlertTypeMutation();
    const [createAlertType] = useCreateAlertTypeMutation();
    const [loadSticker] = useLazyLoadStickerQuery();
    const alertType = alertTypes?.find((item) => item.type === type);
    const [alertStyle, setAlertStyleState] = useState<IAlertTypeItem>(null as unknown as IAlertTypeItem);
    const [selectedPresetId, setSelectedPresetId] = useState<string>();
    const [soundSticker, setSoundSticker] = useState<ISticker>();
    const [soundVolume, setSoundVolume] = useState(50);
    const isLoading = /* !isAlertStylesLoadSuccess ||*/
        !isAlertsLoadSuccess ||
        !alertStyle ||
        !alertTypes;

    const fetchSoundSticker = async () => {
        if (alertType?.alertStyle?.soundId) {
            const { data } = await loadSticker(alertType.alertStyle.soundId);

            setSoundSticker(data);
        }
    };

    const onSelectedPresetChange = (value: string) => setSelectedPresetId(value);

    // COMMON
    const setAlertStyle = (value: Partial<IAlertTypeItem>) => {
        if (isAlertsLoadSuccess) {
            setAlertStyleState({
                ...alertStyle,
                ...value,
            });
        }
    };

    const strokeSizeList = Object.values(StrokeSize).map((item) => ({
        value: item,
        title: t(`strokeSizes.${item}`),
    }));
    const textShadowIndentList = Object.values(TextShadowIndent).map((item) => ({
        value: item,
        title: item,
    }));
    const textShadowBlurList = Object.values(TextShadowBlur).map((item) => ({
        value: item,
        title: item,
    }));
    const fontFamilyList = Object.values(EFontFamily).map((item) => ({
        value: item,
        title: item,
    }));
    const fontSizeList = Object.values(FontSize).map((item) => ({
        value: item,
        title: item,
    }));
    const opacityList = Object.values(EOpacity).map((item) => ({
        value: item,
        title: `${item}%`,
    }));
    const visualEffectInList = Object.values(VISUAL_EFFECTS_IN).map((item) => ({
        value: item,
        title: item,
    }));
    const visualEffectOutList = Object.values(VISUAL_EFFECTS_OUT).map((item) => ({
        value: item,
        title: item,
    }));
    const visualEffectSpeedList = Object.values(VisualEffectSpeed).map((item) => ({
        value: item,
        title: t(`visualEffectsSpeed.${item}`),
    }));

    const setFillValue = (
        oldValue: IFillStyle,
        newValue: Partial<IFillStyle>,
        setter: (value: {[key: string]: IFillStyle}) => void,
        propertySetter = 'fill',
    ) => {
        let newFill = {
            ...oldValue,
            ...newValue,
        };

        if (newValue.fillType !== oldValue.fillType && newValue.fillType === FillType.GRADIENT) {
            newFill = {
                ...newFill,
                primaryColor: DEFAULT_GRADIENT_COLOR_START,
                secondaryColor: DEFAULT_GRADIENT_COLOR_END,
            };
        }

        setter({
            [propertySetter]: newFill,
        });
    };

    const getNormalizedAlertType: (value: IAlertTypeItem) => IAlertTypeItem = (value) => {
        return {
            ...value,
            alertStyle: {
                ...value.alertStyle,
                title: {
                    ...value.alertStyle.title,
                    fontSize: Number(value.alertStyle.title.fontSize) as unknown as FontSize || 0,
                },
                text: {
                    ...value.alertStyle.text,
                    fontSize: Number(value.alertStyle.text.fontSize) as unknown as FontSize || 0,
                },
                textbox: {
                    ...value.alertStyle.textbox,
                    borderRadius: Number(value.alertStyle.textbox.borderRadius) as unknown as ETextBoxBorderRadius || 0,
                },
                soundId: soundSticker?.id,
            },
        };
    };

    const onSubmit = async () => {
        try {
            if (alertType) {
                const updateData = {
                    ...(alertType as IAlertTypeItem),
                    ...alertStyle,
                    alertStyle: {
                        ...alertStyle.alertStyle,
                    },
                } as IAlertTypeItem;

                delete updateData._id;
                delete updateData.alertStyle.createdAt;
                delete updateData.alertStyle.updatedAt;

                await updateAlertType(getNormalizedAlertType(updateData));
            } else {
                await createAlertType(
                    getNormalizedAlertType({
                        ...alertStyle,
                        type,
                        isActive: true,
                    }),
                );
            }

            notify(translation('streamer-settings.notification-texts.changes-saved'), NotificationType.SUCCESS);
        } catch (err) {
            notify(translation('streamer-settings.notification-texts.changes-save-failed'), NotificationType.ERROR);
        }
    };

    const onReset = () => {
        setAlertStyle(DEFAULT_ALERT_STYLE);
    };

    // GENERAL
    const alertSizeList = Object.values(AlertSize).map((item) => ({
        value: item,
        title: t(`sizes.${item}`),
    }));
    const alertDurationList = Object.values(EDuration).map((item) => ({
        value: item,
        title: item + t('sec'),
    }));
    const alertMarginList = Object.values(EMargin).map((item) => ({
        value: item,
        title: item,
    }));

    const onLayoutChange = (value: AlertLayout) => {
        setAlertStyle({
            alertStyle: {
                ...alertStyle.alertStyle,
                layout: value,
            },
        });
    };

    const onAlertSizeChange = (value: AlertSize) => {
        setAlertStyle({
            size: value,
        });
    };

    const onAlertDurationChange = (value: EDuration) => {
        setAlertStyle({
            alertStyle: {
                ...alertStyle.alertStyle,
                duration: Number(value) * 1000,
            },
        });
    };

    const onAlertPositionChange = (value: AlertPosition) => {
        setAlertStyle({
            position: value,
        });
    };

    const onAlertMarginChange = (value: EMargin) => {
        setAlertStyle({
            offsetX: Number(value),
            offsetY: Number(value),
        });
    };

    // STREAMOJI
    const avatarPositionsList = Object.values(StreamojiPosition).map((item) => ({
        value: item,
        title: t(item),
    }));
    const avatarTypesList = Object.values(StreamojiType).map((item) => ({
        value: item,
        title: t(`avatarStyle.${item}`),
    }));
    const avatarSizeList = Object.values(StreamojiSize).map((item) => ({
        value: item,
        title: t(`sizes.${item}`),
    }));

    const onAvatarChange = (value: Partial<IStreamojiStyle>) => {
        setAlertStyle({
            alertStyle: {
                ...alertStyle.alertStyle,
                avatar: {
                    ...alertStyle.alertStyle.avatar,
                    ...value,
                },
            },
        });
    };

    const onAvatarEnabledChange = (value: boolean) => {
        onAvatarChange({
            enabled: value,
        });
    };

    const onAvatarTypeChange = (value: StreamojiType) => {
        onAvatarChange({
            type: value,
        });
    };

    const onAvatarStrokeChange = (stroke: Partial<IStrokeStyle>) => {
        onAvatarChange({
            stroke: {
                ...alertStyle.alertStyle.avatar.stroke,
                ...stroke as IStrokeStyle,
            },
        });
    };

    const onAvatarStrokeSizeChange = (value: StrokeSize) => {
        onAvatarStrokeChange({
            size: value,
        });
    };

    const onAvatarStrokeFillChange = (value: Partial<IFillStyle>) => {
        setFillValue(
            alertStyle.alertStyle.avatar.stroke.fill,
            value,
            onAvatarStrokeChange,
        );
    };

    const onAvatarBackgroundFillChange = (value: Partial<IFillStyle>) => {
        setFillValue(
            alertStyle.alertStyle.avatar.background,
            value,
            onAvatarChange,
            'background',
        );
    };

    const onAvatarPositionChange = (value: StreamojiPosition) => {
        onAvatarChange({
            position: value,
        });
    };

    const onAvatarSizeChange = (value: StreamojiSize) => {
        onAvatarChange({
            size: value,
        });
    };

    const onAvatarEffectsChange = (value: Partial<IVisualEffects>) => {
        onAvatarChange({
            effects: {
                ...alertStyle.alertStyle.avatar.effects,
                ...value as IVisualEffects,
            },
        });
    };

    // TITLE
    const titleTextPositionList = Object.values(TextPosition).map((item) => ({
        value: item,
        title: t(`textPosition.${item}`),
    }));

    const onTitleChange = (value: Partial<ITitleStyle>) => {
        setAlertStyle({
            alertStyle: {
                ...alertStyle.alertStyle,
                title: {
                    ...alertStyle.alertStyle.title,
                    ...value,
                },
            },
        });
    };

    const onTitleEnabledChange = (value: boolean) => {
        onTitleChange({
            enabled: value,
        });
    };

    const onTitleTextChange = (value: string) => {
        onTitleChange({
            text: value,
        });
    };

    const onTitlePositionChange = (value: TextPosition) => {
        onTitleChange({
            position: value,
        });
    };

    const onTitleFontFamilyChange = (value: EFontFamily) => {
        onTitleChange({
            fontFamily: value,
        });
    };

    const onTitleFontSizeChange = (value: FontSize) => {
        onTitleChange({
            fontSize: value,
        });
    };

    const onTitleFillChange = (fill: Partial<IFillStyle>) => {
        setFillValue(
            alertStyle.alertStyle.title.fill,
            fill,
            onTitleChange,
        );
    };

    const onTitleVariablesFillChange = (variablesFill: Partial<IFillStyle>) => {
        setFillValue(
            alertStyle.alertStyle.title.variablesFill,
            variablesFill,
            onTitleChange,
            'variablesFill',
        );
    };

    const onTitleAlignChange = (align: AlignText) => {
        onTitleChange({ align });
    };

    const onTitleStrokeChange = (stroke: Partial<IStrokeStyle>) => {
        onTitleChange({
            stroke: {
                ...alertStyle.alertStyle.title.stroke,
                ...stroke,
            },
        });
    };

    const onTitleStrokeSizeChange = (value: StrokeSize) => {
        onTitleStrokeChange({
            size: value,
        });
    };

    const onTitleStrokeFillChange = (value: Partial<IFillStyle>) => {
        setFillValue(
            alertStyle.alertStyle.title.stroke.fill,
            value,
            onTitleStrokeChange,
        );
    };

    const onTitleTextShadowChange = (textShadow: Partial<ITextShadowStyle>) => {
        onTitleChange({
            textShadow: {
                ...alertStyle.alertStyle.title.textShadow,
                ...textShadow,
            },
        });
    };

    const onTitleTextShadowEnabledChange = (value: boolean) => {
        onTitleTextShadowChange({
            enabled: value,
        });
    };

    const onTitleTextShadowIndentChange = (value: TextShadowIndent) => {
        onTitleTextShadowChange({
            indent: value,
        });
    };

    const onTitleTextShadowBlurChange = (value: TextShadowBlur) => {
        onTitleTextShadowChange({
            blur: value,
        });
    };

    const onTitleTextShadowFillChange = (value: Partial<IFillStyle>) => {
        setFillValue(
            alertStyle.alertStyle.title.textShadow.fill,
            value,
            onTitleTextShadowChange,
        );
    };

    const onTitleEffectsChange = (value: Partial<IVisualEffects>) => {
        onTitleChange({
            effects: {
                ...alertStyle.alertStyle.title.effects,
                ...value as IVisualEffects,
            },
        });
    };

    // TEXT
    const onTextChange = (value: Partial<ITextStyle>) => {
        setAlertStyle({
            alertStyle: {
                ...alertStyle.alertStyle,
                text: {
                    ...alertStyle.alertStyle.text,
                    ...value,
                },
            },
        });
    };

    const onTextEnabledChange = (value: boolean) => {
        onTextChange({
            enabled: value,
        });
    };

    const onTextTextChange = (value: string) => {
        onTextChange({
            text: value,
        });
    };

    const onTextFontFamilyChange = (value: EFontFamily) => {
        onTextChange({
            fontFamily: value,
        });
    };

    const onTextFontSizeChange = (value: FontSize) => {
        onTextChange({
            fontSize: value,
        });
    };

    const onTextFillChange = (fill: Partial<IFillStyle>) => {
        setFillValue(
            alertStyle.alertStyle.text.fill,
            fill,
            onTextChange,
        );
    };

    const onTextVariablesFillChange = (variablesFill: Partial<IFillStyle>) => {
        setFillValue(
            alertStyle.alertStyle.text.variablesFill,
            variablesFill,
            onTextChange,
            'variablesFill',
        );
    };

    const onTextAlignChange = (align: AlignText) => {
        onTextChange({ align });
    };

    const onTextStrokeChange = (stroke: Partial<IStrokeStyle>) => {
        onTextChange({
            stroke: {
                ...alertStyle.alertStyle.text.stroke,
                ...stroke as IStrokeStyle,
            },
        });
    };

    const onTextStrokeSizeChange = (value: StrokeSize) => {
        onTextStrokeChange({
            size: value,
        });
    };

    const onTextStrokeFillChange = (value: Partial<IFillStyle>) => {
        setFillValue(
            alertStyle.alertStyle.text.stroke.fill,
            value,
            onTextStrokeChange,
        );
    };

    const onTextTextShadowChange = (textShadow: Partial<ITextShadowStyle>) => {
        onTextChange({
            textShadow: {
                ...alertStyle.alertStyle.text.textShadow,
                ...textShadow,
            },
        });
    };

    const onTextTextShadowEnabledChange = (value: boolean) => {
        onTextTextShadowChange({
            enabled: value,
        });
    };

    const onTextTextShadowIndentChange = (value: TextShadowIndent) => {
        onTextTextShadowChange({
            indent: value,
        });
    };

    const onTextTextShadowBlurChange = (value: TextShadowBlur) => {
        onTextTextShadowChange({
            blur: value,
        });
    };

    const onTextTextShadowFillChange = (value: Partial<IFillStyle>) => {
        setFillValue(
            alertStyle.alertStyle.text.textShadow.fill,
            value,
            onTextTextShadowChange,
        );
    };

    const onTextEffectsChange = (value: Partial<IVisualEffects>) => {
        onTextChange({
            effects: {
                ...alertStyle.alertStyle.text.effects,
                ...value as IVisualEffects,
            },
        });
    };

    // TEXTBOX
    const textBoxBorderRadiusList = Object.values(ETextBoxBorderRadius).map((item) => ({
        value: item,
        title: item,
    }));

    const onTextBoxChange = (value: Partial<ITextBoxStyle>) => {
        setAlertStyle({
            alertStyle: {
                ...alertStyle.alertStyle,
                textbox: {
                    ...alertStyle.alertStyle.textbox,
                    ...value,
                },
            },
        });
    };

    const onTextBoxEnabledChange = (value: boolean) => {
        onTextBoxChange({
            enabled: value,
        });
    };

    const onTextBoxBorderRadiusChange = (value: ETextBoxBorderRadius) => {
        onTextBoxChange({
            borderRadius: value,
        });
    };

    const onTextBoxOpacityChange = (value: EOpacity) => {
        onTextBoxChange({
            opacity: Number(value),
        });
    };

    const onTextBoxFillChange = (fill: Partial<IFillStyle>) => {
        setFillValue(
            alertStyle.alertStyle.textbox.fill,
            fill,
            onTextBoxChange,
        );
    };

    const onTextBoxStrokeChange = (stroke: Partial<IStrokeStyle>) => {
        onTextBoxChange({
            stroke: {
                ...alertStyle.alertStyle.textbox.stroke,
                ...stroke as IStrokeStyle,
            },
        });
    };

    const onTextBoxStrokeSizeChange = (value: StrokeSize) => {
        onTextBoxStrokeChange({
            size: value,
        });
    };

    const onTextBoxStrokeFillChange = (value: Partial<IFillStyle>) => {
        setFillValue(
            alertStyle.alertStyle.textbox.stroke.fill,
            value,
            onTextBoxStrokeChange,
        );
    };

    const onTextBoxEffectsChange = (value: Partial<IVisualEffects>) => {
        onTextBoxChange({
            effects: {
                ...alertStyle.alertStyle.textbox.effects,
                ...value as IVisualEffects,
            },
        });
    };

    // SOUND
    const onSoundStickerChange = (value?: ISticker) => setSoundSticker(value);

    const onSoundVolumeChange = (value: number) => setSoundVolume(value);

    useEffect(() => {
        console.log('change alertStyle: ', alertStyle);
    }, [alertStyle]);

    useEffect(() => {
        if (isAlertsLoadSuccess) {
            setAlertStyle(alertType || DEFAULT_ALERT_STYLE);

            void fetchSoundSticker();
        }
    }, [isAlertsLoadSuccess]);

    useEffect(
        () => {
            if (selectedPresetId) {
                const preset = presets?.find((item) => item.id === selectedPresetId);

                setAlertStyle({
                    alertStyle: preset,
                });
            }
        },
        [selectedPresetId],
    );

    return {
        isLoading,
        alertStyle,
        presets,
        selectedPresetId,
        soundSticker,
        onSelectedPresetChange,

        // COMMON
        strokeSizeList,
        textShadowIndentList,
        textShadowBlurList,
        fontFamilyList,
        fontSizeList,
        opacityList,
        visualEffectInList,
        visualEffectOutList,
        visualEffectSpeedList,
        onSubmit,
        onReset,

        // GENERAL
        alertSizeList,
        alertDurationList,
        alertMarginList,
        onLayoutChange,
        onAlertSizeChange,
        onAlertDurationChange,
        onAlertPositionChange,
        onAlertMarginChange,

        // STREAMOJI
        avatarPositionsList,
        avatarTypesList,
        avatarSizeList,
        onAvatarChange,
        onAvatarEnabledChange,
        onAvatarTypeChange,
        onAvatarStrokeSizeChange,
        onAvatarStrokeFillChange,
        onAvatarBackgroundFillChange,
        onAvatarPositionChange,
        onAvatarSizeChange,
        onAvatarEffectsChange,

        // TITLE
        titleTextPositionList,
        onTitleEnabledChange,
        onTitleTextChange,
        onTitlePositionChange,
        onTitleFontFamilyChange,
        onTitleFontSizeChange,
        onTitleFillChange,
        onTitleVariablesFillChange,
        onTitleEffectsChange,
        onTitleAlignChange,
        onTitleStrokeSizeChange,
        onTitleStrokeFillChange,
        onTitleTextShadowEnabledChange,
        onTitleTextShadowIndentChange,
        onTitleTextShadowBlurChange,
        onTitleTextShadowFillChange,

        // TEXT
        onTextEnabledChange,
        onTextTextChange,
        onTextFontFamilyChange,
        onTextFontSizeChange,
        onTextFillChange,
        onTextVariablesFillChange,
        onTextEffectsChange,
        onTextAlignChange,
        onTextStrokeSizeChange,
        onTextStrokeFillChange,
        onTextTextShadowEnabledChange,
        onTextTextShadowIndentChange,
        onTextTextShadowBlurChange,
        onTextTextShadowFillChange,

        // TEXTBOX
        textBoxBorderRadiusList,
        onTextBoxEnabledChange,
        onTextBoxBorderRadiusChange,
        onTextBoxOpacityChange,
        onTextBoxFillChange,
        onTextBoxStrokeSizeChange,
        onTextBoxStrokeFillChange,
        onTextBoxEffectsChange,

        // SOUND
        soundVolume,
        onSoundStickerChange,
        onSoundVolumeChange,
    };
};
