// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.CreateForm__root--RPNnx {
  width: 100%;
  min-height: 160px;
}

.CreateForm__input--uM95L {
  width: 100%;
  margin-bottom: 20px;
}

.CreateForm__form--Swamn {
  width: 100%;
  flex: 1;
}

.CreateForm__buttonsItem--dGhOH {
  margin-right: 10px;
}
.CreateForm__buttonsItem--dGhOH:last-child {
  margin-right: 0;
}`, "",{"version":3,"sources":["webpack://./src/base/views/pages/StreamerSettings/components/AlertSettings/AlertsSettingsType/components/Preset/components/CreateForm/CreateForm.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;EACA,iBAAA;AACJ;;AAEA;EACI,WAAA;EACA,mBAAA;AACJ;;AAEA;EACI,WAAA;EACA,OAAA;AACJ;;AAGI;EACI,kBAAA;AAAR;AAEQ;EACI,eAAA;AAAZ","sourcesContent":[".root {\n    width: 100%;\n    min-height: 160px;\n}\n\n.input {\n    width: 100%;\n    margin-bottom: 20px;\n}\n\n.form {\n    width: 100%;\n    flex: 1;\n}\n\n.buttons {\n    &Item {\n        margin-right: 10px;\n\n        &:last-child {\n            margin-right: 0;\n        }\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `CreateForm__root--RPNnx`,
	"input": `CreateForm__input--uM95L`,
	"form": `CreateForm__form--Swamn`,
	"buttonsItem": `CreateForm__buttonsItem--dGhOH`
};
export default ___CSS_LOADER_EXPORT___;
