export enum AudioFormat {
    MP3 = 'mp3',
    PCM = 'pcm',
    OGG = 'ogg_vorbis'
}

export interface IVoice {
    id: string;
    name: string;
    gender: string;
    languages: Array<string>;
    supportedEngines: Array<'standard' | 'generative' | 'neural' | 'long-form'>;
}

export interface IVoicesCategory {
    id: string;
    name: string;
    description: string;
    order: number;
    hidden: boolean;
    voices: Array<IVoiceDescriptor>;
}

export type TVoiceParameterName = 'provider' | 'engine' | 'output' | 'language' | 'paid';
export type TVoiceParameterValueType = string | number | boolean;

export type TVoiceParameters = Partial<Record<TVoiceParameterName, TVoiceParameterValueType>>;

export interface IVoiceTag {
    tag: string;
}

export interface IVoiceDescriptor {
    id: string;
    provider: string;
    voice: string;
    name: string;
    parameters: TVoiceParameters;
    tags?: Array<string>;
    phrase?: string;
}
