import React from 'react';
import { useTranslation } from 'react-i18next';

import s from './VoiceTooltip.scss';

interface IVoiceTooltipProps {
    onClick(): void;
}

export const VoiceTooltip = (props: IVoiceTooltipProps) => {
    const { onClick } = props;
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`sticker-popup.controlsSend.${id}`);

    return (
        <div className={s.root} onClick={onClick}>
            {t('voiceTooltip')}

            <div className={s.arrow}/>
        </div>
    );
};
