import { useApi } from '@hooks';
import { AlertType } from '@typings';

import { DEFAULT_ALERT_STYLE } from '../../../const';

export const useAlertsSettings = () => {
    const {
        alerts: {
            useLoadAlertsQuery,
            useCreateAlertTypeMutation,
            useUpdateAlertTypeMutation,
        },
    } = useApi();
    const {
        data: alertTypes,
        isSuccess: isAlertTypesLoadSuccess,
    } = useLoadAlertsQuery();
    const [createAlertType] = useCreateAlertTypeMutation();
    const [updateAlertType] = useUpdateAlertTypeMutation();
    const isLoading = !isAlertTypesLoadSuccess;

    const onActiveChange = (type: AlertType, value: boolean) => {
        const item = alertTypes?.find((item) => item.type === type);

        if (item) {
            updateAlertType({
                ...item,
                isActive: value,
            });
        } else {
            createAlertType({
                type,
                isActive: value,
                ...DEFAULT_ALERT_STYLE,
            });
        }
    };

    return {
        isLoading,
        alertTypes,
        onActiveChange,
    };
};
