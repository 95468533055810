import { ERowAlignment, Row } from '@hyperclap/ui';
import * as React from 'react';
import { PropsWithChildren } from 'react';

import s from './FormRow.scss';

interface IFormRowProps {
    alignment?: ERowAlignment;
}

export const FormRow = (props: PropsWithChildren<IFormRowProps>) => {
    const {
        alignment,
        children,
    } = props;

    return (
        <Row
            className={s.root}
            alignment={alignment || ERowAlignment.BOTTOM}
        >
            {children}
        </Row>
    );
};
