import { Column, EColumnAlignment, EColumnJustification, ETooltipPosition, Row, Tooltip } from '@hyperclap/ui';
import cn from 'classnames';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { IconAngleDownLine, IconAngleUpLine, IconRepeat, IconRepeatOff, IconSoundActiveLine, IconSoundMuteLine } from '@assets/images/svg';
import { ISticker, SizeCalculationBase } from '@typings';
import { AuthButtons, EStickerPopupControlsMode, Sticker } from '@views';

import s from './Content.scss';
import { useContent } from './hooks';
import { IStickerPopupDataExtended } from '../../types';
import { Controls, ControlsSend, ControlsStreamer, ControlsViewer, SentCounter } from '../components';

interface IContentProps extends IStickerPopupDataExtended {
    sticker: ISticker;
    updateSticker(stickerData: ISticker): void;
    onNextClick(): void;
    onPrevClick(): void;
}

export const Content = (props: IContentProps) => {
    const {
        sticker,
        controlsMode,
        currentUser: { currentUser },
        updateSticker,
        onNextClick,
        onPrevClick,
    } = props;
    const videoRef = useRef<HTMLVideoElement>();
    const {
        isPreviewLoaded,
        loop,
        withSound,
        onLoopToggle,
        onWithSoundToggle,
    } = useContent({
        sticker,
        videoRef,
    });
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`sticker-popup.${id}`);

    return (
        <Row className={cn(
            s.root,
            {
                [s.streamer]: controlsMode === EStickerPopupControlsMode.STREAMER,
                [s.viewer]: controlsMode === EStickerPopupControlsMode.VIEWER,
                [s.send]: controlsMode === EStickerPopupControlsMode.SEND,
            },
        )}>
            <Column className={s.left}>
                <div
                    className={cn(s.stickerBg, { [s.stickerBgReady]: isPreviewLoaded })}
                    style={{
                        backgroundImage: `url("${sticker.stickerPreview}")`,
                    }}
                />

                <Sticker
                    className={s.sticker}
                    classNameVideo={s.stickerVideo}
                    sticker={sticker}
                    withSound={withSound}
                    loop={loop}
                    sizeCalculationBase={SizeCalculationBase.HEIGHT}
                    useRecalculateDimensions={false}
                    forceEnabled={true}
                    videoRef={videoRef}
                />

                <div className={s.leftControls}>
                    <div className={s.navigation}>
                        <div
                            className={cn(s.navigationControl, s.navigationControlPrev)}
                            onClick={onPrevClick}
                        >
                            <IconAngleUpLine className={s.navigationControlIcon}/>
                        </div>

                        <div
                            className={cn(s.navigationControl, s.navigationControlNext)}
                            onClick={onNextClick}
                        >
                            <IconAngleDownLine className={s.navigationControlIcon}/>
                        </div>
                    </div>

                    <div className={s.settings}>
                        <Tooltip
                            classNames={{ root: s.settingsControlTooltip }}
                            position={ETooltipPosition.TOP}
                            content={loop ? t('navigation.loop.off') : t('navigation.loop.on')}
                        >
                            <div
                                className={s.settingsControl}
                                onClick={onLoopToggle}
                            >
                                {loop
                                    ? <IconRepeat className={s.settingsControlIcon}/>
                                    : <IconRepeatOff className={s.settingsControlIcon}/>
                                }
                            </div>
                        </Tooltip>

                        <Tooltip
                            classNames={{ root: s.settingsControlTooltip }}
                            position={ETooltipPosition.TOP}
                            content={withSound ? t('navigation.sound.off') : t('navigation.sound.on')}
                        >
                            <div
                                className={s.settingsControl}
                                onClick={onWithSoundToggle}
                            >
                                {withSound
                                    ? <IconSoundActiveLine className={s.settingsControlIcon}/>
                                    : <IconSoundMuteLine className={s.settingsControlIcon}/>
                                }
                            </div>
                        </Tooltip>
                    </div>
                </div>
            </Column>

            <Column
                className={s.right}
                alignment={EColumnAlignment.BEGIN}
                justification={EColumnJustification.CENTER}
            >
                {currentUser
                    ? (
                        <>
                            <div className={s.stickerName}>{sticker.name}</div>

                            <Row className={s.controls}>
                                {controlsMode === EStickerPopupControlsMode.SEND && (
                                    <ControlsSend {...props}/>
                                )}

                                {controlsMode === EStickerPopupControlsMode.VIEWER && (
                                    <ControlsViewer
                                        sticker={sticker}
                                        currentUser={currentUser}
                                        updateSticker={updateSticker}
                                    />
                                )}

                                {controlsMode === EStickerPopupControlsMode.STREAMER && (
                                    <ControlsStreamer
                                        sticker={sticker}
                                        currentUser={currentUser}
                                        updateSticker={updateSticker}
                                    />
                                )}
                            </Row>


                        </>
                    )
                    : (
                        <>
                            <div className={s.authTitle}>{t('auth.title')}</div>

                            <AuthButtons
                                className={s.auth}
                                hideVK={true}
                                returnUrl={location.pathname}
                            />
                        </>
                    )
                }

                <SentCounter sticker={sticker}/>

                <Controls {...props}/>
            </Column>
        </Row>
    );
};
