// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TitleForm__text--G8tHv {
  width: 100%;
}

.TitleForm__positionItem--TMF0l {
  width: 70px;
  margin-right: 25px;
}

.TitleForm__fontFamily--wzMwa {
  width: 260px;
}
.TitleForm__fontFamilyOption--L4JmH {
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.TitleForm__size--qH3LZ,
.TitleForm__stroke--QXsTx,
.TitleForm__fade--b_nCe {
  margin-right: 10px;
}
.TitleForm__sizeSelect--MG0_U,
.TitleForm__strokeSelect--UbzqY,
.TitleForm__fadeSelect--V884g {
  margin-right: 5px;
}
.TitleForm__sizeSelectControlInput--e5yVS,
.TitleForm__strokeSelectControlInput--SSEE7,
.TitleForm__fadeSelectControlInput--V5m1L {
  min-width: 20px;
}

.TitleForm__sizeSelect--MG0_U {
  width: 75px;
}

.TitleForm__fadeSelect--V884g {
  width: 145px;
}

.TitleForm__strokeSelect--UbzqY {
  width: 125px;
}

.TitleForm__colorPicker--lTswt {
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/base/views/pages/StreamerSettings/components/AlertSettings/AlertsSettingsType/components/Elements/components/FormSwitcher/components/forms/TitleForm/TitleForm.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AAGI;EACI,WAAA;EACA,kBAAA;AAAR;;AAIA;EACI,YAAA;AADJ;AAGI;EACI,YAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;AADR;;AAKA;;;EAGI,kBAAA;AAFJ;AAII;;;EACI,iBAAA;AAAR;AAEQ;;;EACI,eAAA;AAEZ;;AAII;EACI,WAAA;AADR;;AAMI;EACI,YAAA;AAHR;;AAQI;EACI,YAAA;AALR;;AASA;EACI,iBAAA;AANJ","sourcesContent":[".text {\n    width: 100%;\n}\n\n.position {\n    &Item {\n        width: 70px;\n        margin-right: 25px;\n    }\n}\n\n.fontFamily {\n    width: 260px;\n\n    &Option {\n        width: 220px;\n        overflow: hidden;\n        text-overflow: ellipsis;\n        white-space: nowrap;\n    }\n}\n\n.size,\n.stroke,\n.fade {\n    margin-right: 10px;\n\n    &Select {\n        margin-right: 5px;\n\n        &ControlInput {\n            min-width: 20px;\n        }\n    }\n}\n\n.size {\n    &Select {\n        width: 75px;\n    }\n}\n\n.fade {\n    &Select {\n        width: 145px;\n    }\n}\n\n.stroke {\n    &Select {\n        width: 125px;\n    }\n}\n\n.colorPicker {\n    margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `TitleForm__text--G8tHv`,
	"positionItem": `TitleForm__positionItem--TMF0l`,
	"fontFamily": `TitleForm__fontFamily--wzMwa`,
	"fontFamilyOption": `TitleForm__fontFamilyOption--L4JmH`,
	"size": `TitleForm__size--qH3LZ`,
	"stroke": `TitleForm__stroke--QXsTx`,
	"fade": `TitleForm__fade--b_nCe`,
	"sizeSelect": `TitleForm__sizeSelect--MG0_U`,
	"strokeSelect": `TitleForm__strokeSelect--UbzqY`,
	"fadeSelect": `TitleForm__fadeSelect--V884g`,
	"sizeSelectControlInput": `TitleForm__sizeSelectControlInput--e5yVS`,
	"strokeSelectControlInput": `TitleForm__strokeSelectControlInput--SSEE7`,
	"fadeSelectControlInput": `TitleForm__fadeSelectControlInput--V5m1L`,
	"colorPicker": `TitleForm__colorPicker--lTswt`
};
export default ___CSS_LOADER_EXPORT___;
