import { ISelectProps, Row, Select } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import cn from 'classnames';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { IFillStyle } from '@typings';

import s from './FieldStroke.scss';
import { Field, IBaseFieldProps } from '../Field';
import { FieldColorPicker } from '../FieldColorPicker';


interface IFieldStrokeProps extends IBaseFieldProps, ISelectProps {
    classNameSelect?: string;
    hiddenLabel?: boolean;
    fill?: IFillStyle;
    isFlatOnly?: boolean;
    disabled?: boolean;
    onFillChange?(value: IFillStyle): void;
}

export const FieldStroke = (props: IFieldStrokeProps) => {
    const {
        className,
        classNameSelect,
        label,
        hiddenLabel,
        fill,
        isFlatOnly,
        disabled,
        onFillChange = noop,
        ...selectProps
    } = props;
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`streamer-settings.alerts-type.forms.${id}`);
    const fieldLabel = hiddenLabel ? '' : label || t('stroke');

    return (
        <Field
            className={className}
            label={fieldLabel}
            disabled={disabled}
            content={
                <Row>
                    <Select
                        className={cn(s.select, classNameSelect)}
                        disabled={disabled}
                        {...selectProps}
                    />

                    <FieldColorPicker
                        value={fill}
                        isFlatOnly={isFlatOnly}
                        disabled={disabled}
                        onChange={onFillChange}
                    />
                </Row>
            }
        />
    );
};
