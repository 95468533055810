import { Select, ISelectProps } from '@hyperclap/ui';
import * as React from 'react';

import { Field, IBaseFieldProps } from '../Field';

export interface IFieldSelectProps extends IBaseFieldProps, ISelectProps {
    classNameField?: string;
}

export const FieldSelect = (props: IFieldSelectProps) => {
    const { label, classNameField, disabled, ...selectProps } = props;

    return (
        <Field
            label={label}
            className={classNameField}
            disabled={disabled}
            content={(
                <Select
                    {...selectProps}
                    disabled={disabled}
                />
            )}
        />
    );
};
