// eslint-disable-next-line import/no-unresolved
import config from 'config';
import { RefObject, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { NotificationType } from '@contexts';
import { useApi, useCurrentUser, useNotifications } from '@hooks';
import { IVoiceDescriptor, IVoiceTag } from '@typings';

export const ALL_CATEGORY_ID = '__ALL__';

export const TEST_PHRASE = 'Привет! У тебя классный стрим. Люблю тебя!';

export const useVoices = () => {
    const {
        voices: {
            useLoadVoiceCategoriesQuery,
            useLoadVoiceTagsQuery,
        },
        users: {
            useUpdateUserVoiceMutation,
        },
    } = useApi();

    const {
        currentUser,
    } = useCurrentUser();

    const { notify } = useNotifications();
    const { t } = useTranslation();

    const [selectedTags, setSelectedTags] = useState<string[]>([]);

    const { data: voicesCategories, isFetching: isVoicesFetching } = useLoadVoiceCategoriesQuery();
    const { data: voiceTags, isFetching: isTagsFetching } = useLoadVoiceTagsQuery();
    const [updateUserVoice] = useUpdateUserVoiceMutation();

    const [selectedCategory, setSelectedCategory] = useState<string>(ALL_CATEGORY_ID);
    const [voicesInCategory, setVoicesInCategory] = useState<IVoiceDescriptor[]>([]);
    const [playedVoiceId, setPlayedVoiceId] = useState<string>('');
    const [userVoice, setUserVoice] = useState<string>();
    const [isVoicingEnabledOnChannel, setIsVoicingEnabledOnChannel] = useState<boolean>(true);

    const switchTagSelection = (tag: IVoiceTag): void => {
        setSelectedTags(
            !selectedTags.includes(tag.tag)
                ? [...selectedTags, tag.tag]
                : selectedTags.filter((t) => t !== tag.tag),
        );
    };

    const stopVoicePlaying = (player: RefObject<HTMLAudioElement>) => {
        if (playedVoiceId && player?.current) {
            player.current.pause();
            player.current.src = '';
            player.current.onended = null;
            player.current.onerror = null;
            setPlayedVoiceId('');
        }
    };

    /* This method uses endpoint with cache and high rate-limits (10 RPS) */
    const playVoice = (voice: IVoiceDescriptor, phrase: string, player: RefObject<HTMLAudioElement>) => {
        const ttsProxy = config?.hosts?.ttsProxyApiHost;

        if (playedVoiceId && player?.current) {
            player.current.pause();
            player.current.src = '';
            player.current.onended = null;
            player.current.onerror = null;
            setPlayedVoiceId('');
        }

        if (!ttsProxy) {
            notify(t('notifications.no-tts-proxy'), NotificationType.ERROR);

            return;
        }

        if (player?.current) {
            player.current.src = `${
                ttsProxy
            }/tts/play?providerName=${
                voice.provider
            }&voiceId=${
                voice.voice
            }&text=${
                phrase
            }&outputFormat=ogg`;

            player.current.onended = () => {
                if (player.current) {
                    player.current.onended = null;
                    setPlayedVoiceId('');
                }
            };

            player.current.onerror = () => {
                if (player.current) {
                    player.current.onended = null;
                    setPlayedVoiceId('');
                }
            };

            setPlayedVoiceId(voice.id);
            void player.current.play();
        }
    };

    /* This method uses endpoint without cache and low rate-limits (1 RPS) */
    const convertVoice = (voice: IVoiceDescriptor, phrase: string, player: RefObject<HTMLAudioElement>) => {
        const ttsProxy = config?.hosts?.ttsProxyApiHost;

        if (playedVoiceId && player?.current) {
            player.current.pause();
            player.current.src = '';
            player.current.onended = null;
            player.current.onerror = null;
            setPlayedVoiceId('');
        }

        if (!ttsProxy) {
            notify(t('notifications.no-tts-proxy'), NotificationType.ERROR);

            return;
        }

        if (player?.current) {
            player.current.src = `${
                ttsProxy
            }/tts/convert?providerName=${
                voice.provider
            }&voiceId=${
                voice.voice
            }&text=${
                phrase
            }&outputFormat=ogg`;

            player.current.onended = () => {
                if (player.current) {
                    player.current.onended = null;
                    setPlayedVoiceId('');
                }
            };

            player.current.onerror = () => {
                if (player.current) {
                    player.current.onended = null;
                    setPlayedVoiceId('');
                }
            };

            setPlayedVoiceId(voice.id);
            void player.current.play();
        }
    };

    const selectUserVoice = (voice: IVoiceDescriptor) => {
        setUserVoice(voice.id);
        updateUserVoice(voice);
    };

    useEffect(() => {
        if (currentUser?.voice) {
            setUserVoice(currentUser.voice.id);
            setIsVoicingEnabledOnChannel(currentUser.channel.isStickersMessagesTtsEnabled ?? true);
        }
    }, [currentUser]);

    useEffect(() => {
        if (voicesCategories?.length) {
            setSelectedCategory(ALL_CATEGORY_ID);
        }
    }, [voicesCategories]);

    useEffect(() => {
        if (selectedCategory) {
            const voices = selectedCategory !== ALL_CATEGORY_ID
                ? voicesCategories?.find((category) => category.id === selectedCategory)?.voices
                : voicesCategories?.map((category) => category.voices)?.flat();

            const filteredVoices = selectedTags.length
                // ? voices?.filter((v) => v.tags?.some((t) => selectedTags.includes(t))) ?? []
                ? voices?.filter((v) => selectedTags.every((st) => v.tags?.includes(st))) ?? []
                : voices;

            // filteredVoices?.sort((a, b) => (a.name >= b.name ? 1 : -1));

            setVoicesInCategory(filteredVoices ?? []);
        }
    }, [selectedCategory, selectedTags]);

    return {
        TEST_PHRASE,

        playedVoiceId,
        selectedCategory,
        isVoicesFetching,
        isVoicingEnabledOnChannel,
        isTagsFetching,
        voicesCategories,
        voicesInCategory,
        voiceTags,
        selectedTags,
        userVoice,
        playVoice,
        convertVoice,
        stopVoicePlaying,
        setSelectedCategory,
        switchTagSelection,
        selectUserVoice,
    };
};
