// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextForm__text--FMu7R {
  width: 100%;
}

.TextForm__fontFamily--qQNTk {
  width: 260px;
}
.TextForm__fontFamilyOption--EoIcO {
  width: 220px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.TextForm__size--sk11Z,
.TextForm__stroke--_OuG2,
.TextForm__fade--FqGZB {
  margin-right: 10px;
}
.TextForm__sizeSelect--XcVxt,
.TextForm__strokeSelect--CBlkL,
.TextForm__fadeSelect--PEyFC {
  margin-right: 5px;
}
.TextForm__sizeSelectControlInput--CY5sE,
.TextForm__strokeSelectControlInput--NdlEx,
.TextForm__fadeSelectControlInput--muOIM {
  min-width: 20px;
}

.TextForm__sizeSelect--XcVxt {
  width: 75px;
}

.TextForm__fadeSelect--PEyFC {
  width: 145px;
}

.TextForm__strokeSelect--CBlkL {
  width: 125px;
}

.TextForm__colorPicker--jWvuo {
  margin-right: 5px;
}`, "",{"version":3,"sources":["webpack://./src/base/views/pages/StreamerSettings/components/AlertSettings/AlertsSettingsType/components/Elements/components/FormSwitcher/components/forms/TextForm/TextForm.scss"],"names":[],"mappings":"AAAA;EACI,WAAA;AACJ;;AAEA;EACI,YAAA;AACJ;AACI;EACI,YAAA;EACA,gBAAA;EACA,uBAAA;EACA,mBAAA;AACR;;AAGA;;;EAGI,kBAAA;AAAJ;AAEI;;;EACI,iBAAA;AAER;AAAQ;;;EACI,eAAA;AAIZ;;AAEI;EACI,WAAA;AACR;;AAII;EACI,YAAA;AADR;;AAMI;EACI,YAAA;AAHR;;AAOA;EACI,iBAAA;AAJJ","sourcesContent":[".text {\n    width: 100%;\n}\n\n.fontFamily {\n    width: 260px;\n\n    &Option {\n        width: 220px;\n        overflow: hidden;\n        text-overflow: ellipsis;\n        white-space: nowrap;\n    }\n}\n\n.size,\n.stroke,\n.fade {\n    margin-right: 10px;\n\n    &Select {\n        margin-right: 5px;\n\n        &ControlInput {\n            min-width: 20px;\n        }\n    }\n}\n\n.size {\n    &Select {\n        width: 75px;\n    }\n}\n\n.fade {\n    &Select {\n        width: 145px;\n    }\n}\n\n.stroke {\n    &Select {\n        width: 125px;\n    }\n}\n\n.colorPicker {\n    margin-right: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"text": `TextForm__text--FMu7R`,
	"fontFamily": `TextForm__fontFamily--qQNTk`,
	"fontFamilyOption": `TextForm__fontFamilyOption--EoIcO`,
	"size": `TextForm__size--sk11Z`,
	"stroke": `TextForm__stroke--_OuG2`,
	"fade": `TextForm__fade--FqGZB`,
	"sizeSelect": `TextForm__sizeSelect--XcVxt`,
	"strokeSelect": `TextForm__strokeSelect--CBlkL`,
	"fadeSelect": `TextForm__fadeSelect--PEyFC`,
	"sizeSelectControlInput": `TextForm__sizeSelectControlInput--CY5sE`,
	"strokeSelectControlInput": `TextForm__strokeSelectControlInput--NdlEx`,
	"fadeSelectControlInput": `TextForm__fadeSelectControlInput--muOIM`,
	"colorPicker": `TextForm__colorPicker--jWvuo`
};
export default ___CSS_LOADER_EXPORT___;
