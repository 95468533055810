import { Divider } from '@hyperclap/ui';
import cn from 'classnames';
import * as React from 'react';

interface IMenuItemProps {
    icon: React.FunctionComponent<React.SVGProps<SVGSVGElement>>;
    title: string;
    isActive: boolean;
    onClick(): void;
}

import s from './MenuItem.scss';

export const MenuItem = (props: IMenuItemProps) => {
    const {
        icon: Icon,
        title,
        isActive,
        onClick,
    } = props;

    return (
        <div
            className={cn(s.root, { [s.active]: isActive })}
            onClick={onClick}
        >
            <div className={s.content}>
                <Icon className={s.icon}/>
                <div className={s.title}>{title}</div>
            </div>

            <Divider className={s.divider}/>
        </div>
    );
};
