import { Row } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { AlignText } from '@typings';

import {
    IconAlignLeft,
    IconAlignCenter,
    IconAlignRight,
} from './assets';
import s from './FieldAlign.scss';
import { IconRadioGroup } from '../../../../../IconRadioGroup';
import { Field, IBaseFieldProps } from '../Field';


interface IFieldAlignProps extends IBaseFieldProps {
    className?: string;
    align?: AlignText;
    onChange?(value: AlignText): void;
}

export const FieldAlign = (props: IFieldAlignProps) => {
    const {
        className,
        align,
        label,
        onChange = noop,
    } = props;
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`streamer-settings.alerts-type.forms.${id}`);

    const handleChange = (value: string) => onChange(value as AlignText);

    return (
        <Field
            className={className}
            label={label || t('align')}
            content={
                <Row>
                    <IconRadioGroup
                        classNames={{
                            root: s.root,
                            // item: s.item,
                            // icon: s.icon,
                        }}
                        items={[
                            {
                                value: AlignText.LEFT,
                                icon: IconAlignLeft,
                                // className: s.horizontal,
                            },
                            {
                                value: AlignText.CENTER,
                                icon: IconAlignCenter,
                                // className: s.vertical,
                            },
                            {
                                value: AlignText.RIGHT,
                                icon: IconAlignRight,
                                // className: s.vertical,
                            },
                        ]}
                        value={align as AlignText}
                        onChange={handleChange}
                    />
                </Row>
            }
        />
    );
};
