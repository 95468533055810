import { ThemeContext } from '@hyperclap/ui';
import React, { useContext, useLayoutEffect } from 'react';

import { TApp } from '@hooks';
import { Footer } from '@memealerts/views';
import { DesktopPage, MobilePage } from '@views';

import { LeftSideMenu } from './components';
import { Header } from '../PersonalAreaPage/components';


interface IStreamerSettingsPage extends TApp {
    noRightSidebar?: boolean;
}

export const StreamerSettingsPage = (props: React.PropsWithChildren<IStreamerSettingsPage>) => {
    const { forceUpdateTheme } = useContext(ThemeContext);

    const {
        children,
        noRightSidebar,
        deviceInfo: {
            isMobileLayoutRecommended,
        },
    } = props;

    useLayoutEffect(() => {
        forceUpdateTheme();
    }, []);

    const header = <Header {...props}/>;
    const footer = <Footer {...props} hideThemeSwitch/>;
    const leftMenu = <LeftSideMenu {...props}/>;

    return (
        isMobileLayoutRecommended
            ? <MobilePage header={header}>
                {children}
            </MobilePage>
            : <DesktopPage header={header} footer={footer} leftSidebar={leftMenu} hideRightSidebar={noRightSidebar} stickyHeader>
                {children}
            </DesktopPage>
    );
};
