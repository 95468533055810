import {
    Row,
    Switch,
    EComponentColor,
    ERowAlignment,
    ISelectOption,
} from '@hyperclap/ui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { IFillStyle, ITextShadowStyle, TextShadowBlur, TextShadowIndent } from '@typings';

import s from './FieldTextShadow.scss';
import { Field, IBaseFieldProps } from '../Field';
import { FieldColorPicker } from '../FieldColorPicker';
import { FieldSelect } from '../FieldSelect';
import { FormRowField } from '../FormRowField';


interface IFieldTextShadowProps extends IBaseFieldProps {
    className?: string;
    textShadow: ITextShadowStyle;
    indentList: { title: string; value: string }[];
    blurList: { title: string; value: string }[];
    onEnabledChange(value: boolean): void;
    onIntentChange(value: TextShadowIndent): void;
    onBlurChange(value: TextShadowBlur): void;
    onFillChange(value: Partial<IFillStyle>): void;
}


export const FieldTextShadow = (props: IFieldTextShadowProps) => {
    const {
        className,
        textShadow,
        indentList,
        blurList,
        onEnabledChange,
        onIntentChange,
        onBlurChange,
        onFillChange,
    } = props;

    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`streamer-settings.alerts-type.forms.${id}`);

    const handleIndentChange = (selection?: ISelectOption) => {
        onIntentChange(selection?.value as TextShadowIndent);
    };

    const handleBlurChange = (selection?: ISelectOption) => {
        onBlurChange(selection?.value as TextShadowBlur);
    };

    return (
        <Field
            className={className}
            content={
                <Row alignment={ERowAlignment.BOTTOM}>
                    <FormRowField>
                        <Field
                            className={s.switchField}
                            classNameLabel={s.switchFieldLabel}
                            label={t('fade')}
                            content={(
                                <Switch
                                    color={EComponentColor.ACCENT}
                                    checked={textShadow.enabled}
                                    onChange={onEnabledChange}
                                />
                            )}
                        />
                    </FormRowField>

                    <FormRowField>
                        <Row alignment={ERowAlignment.BOTTOM}>
                            <FieldSelect
                                className={s.select}
                                classNameControlInput={s.selectControlInput}
                                label={t('fadeIndent')}
                                disabled={!textShadow.enabled}
                                options={indentList.map((item) => ({
                                    caption: item.title,
                                    value: item.value,
                                    selected: textShadow.indent === item.value,
                                }))}
                                onSelectionChanged={handleIndentChange}
                            />
                            <FieldColorPicker
                                disabled={!textShadow.enabled}
                                isFlatOnly={true}
                                value={textShadow.fill}
                                onChange={onFillChange}
                            />
                        </Row>
                    </FormRowField>

                    <FormRowField>
                        <FieldSelect
                            className={s.select}
                            classNameControlInput={s.selectControlInput}
                            label={t('fadeBlur')}
                            disabled={!textShadow.enabled}
                            options={blurList.map((item) => ({
                                caption: item.title,
                                value: item.value,
                                selected: textShadow.blur === item.value,
                            }))}
                            onSelectionChanged={handleBlurChange}
                        />
                    </FormRowField>
                </Row>
            }
        />
    );
};
