// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FieldTextShadow__switchField--UKSwQ {
  height: 74px;
}
.FieldTextShadow__switchFieldLabel--nr2ZO {
  margin-bottom: 10px;
}

.FieldTextShadow__select--QH2sr {
  width: 75px;
  margin-right: 5px;
}
.FieldTextShadow__selectControlInput--bwY5s {
  min-width: 20px;
}`, "",{"version":3,"sources":["webpack://./src/base/views/pages/StreamerSettings/components/AlertSettings/AlertsSettingsType/components/Elements/components/FormSwitcher/components/FieldTextShadow/FieldTextShadow.scss"],"names":[],"mappings":"AAAA;EACI,YAAA;AACJ;AACI;EACI,mBAAA;AACR;;AAGA;EACI,WAAA;EACA,iBAAA;AAAJ;AAEI;EACI,eAAA;AAAR","sourcesContent":[".switchField {\n    height: 74px;\n\n    &Label {\n        margin-bottom: 10px;\n    }\n}\n\n.select {\n    width: 75px;\n    margin-right: 5px;\n\n    &ControlInput {\n        min-width: 20px;\n    }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"switchField": `FieldTextShadow__switchField--UKSwQ`,
	"switchFieldLabel": `FieldTextShadow__switchFieldLabel--nr2ZO`,
	"select": `FieldTextShadow__select--QH2sr`,
	"selectControlInput": `FieldTextShadow__selectControlInput--bwY5s`
};
export default ___CSS_LOADER_EXPORT___;
