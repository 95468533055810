import cn from 'classnames';
import React, { useEffect, useRef } from 'react';

import { FillType, IAlertTypeItem, StrokeSize } from '@typings';
import { VISUAL_EFFECT_SPEED_MAP } from '@views/pages/StreamerSettings/components/AlertSettings/const';

import s from './Content.scss';
import { EAnimationState } from '../../enums';
import { Text } from '../Text';
import { Title } from '../Title';

interface IContentProps {
    className?: string;
    alert: IAlertTypeItem;
    titleVariables: Record<string, string>;
    textVariables: Record<string, string>;
    animationState?: EAnimationState;
}

const STROKE_SIZE_MAP = {
    [StrokeSize.NONE]: 0,
    [StrokeSize.S]: 2,
    [StrokeSize.M]: 4,
    [StrokeSize.L]: 6,
};

export const Content = (props: IContentProps) => {
    const {
        className,
        alert: {
            alertStyle: {
                title,
                text,
                textbox,
            },
        },
        titleVariables,
        textVariables,
        animationState,
    } = props;
    const rootRef = useRef<HTMLDivElement>(null);
    const textBoxFillStyles = textbox.fill.fillType === FillType.FLAT
        ? {
            backgroundColor: textbox.fill.primaryColor,
        }
        : {
            background: `linear-gradient(${textbox.fill.primaryColor}, ${textbox.fill.secondaryColor})`,
        };
    const textBoxBorderStyles = textbox.stroke.fill.fillType === FillType.FLAT
        ? { border: `solid ${STROKE_SIZE_MAP[textbox.stroke.size]}px ${textbox.stroke.fill.primaryColor}` }
        : {};
    const textBoxBgStyles = {
        ...textBoxFillStyles,
        opacity: (100 - textbox.opacity) / 100,
        borderRadius: `${textbox.borderRadius}px`,
    };
    const textBoxBgBorderStyles = {
        ...textBoxBorderStyles,
        borderRadius: `${textbox.borderRadius}px`,
    };
    const duration = animationState === EAnimationState.NONE
        ? 0
        : (
            animationState === EAnimationState.IN
                ? VISUAL_EFFECT_SPEED_MAP[textbox.effects.in.speed]
                : VISUAL_EFFECT_SPEED_MAP[textbox.effects.out.speed]
        );

    const content = (
        <div className={cn(
            s.align,
            s[`align-${title.position}`],
        )}>
            {title.enabled && (
                <Title
                    className={s.title}
                    vars={titleVariables}
                    titleStyle={title}
                    animationState={animationState}
                />
            )}

            {text.enabled && (
                <Text
                    className={s.text}
                    vars={textVariables}
                    textStyle={text}
                    animationState={animationState}
                />
            )}
        </div>
    );

    useEffect(
        () => {
            if (rootRef.current) {
                rootRef.current.style.setProperty('--textbox-border-size', `${STROKE_SIZE_MAP[textbox.stroke.size]}px`);
                rootRef.current.style.setProperty('--textbox-border-radius', `${textbox.borderRadius}px`);
                rootRef.current.style.setProperty('--textbox-border-primary-color', textbox.stroke.fill.primaryColor);
                rootRef.current.style.setProperty('--textbox-border-secondary-color', textbox.stroke.fill.secondaryColor as string);
            }
        },
        [
            rootRef?.current,
            textbox,
        ],
    );

    useEffect(() => {
        rootRef?.current?.style?.setProperty('--animate-duration', `${duration}s`);
    }, [duration]);

    return (
        <div
            className={
                cn(
                    s.root,
                    {
                        'animate__animated': animationState !== EAnimationState.NONE,
                        [`animate__${textbox.effects.in.effect}`]: animationState === EAnimationState.IN,
                        [`animate__${textbox.effects.out.effect}`]: animationState === EAnimationState.OUT,
                    },
                    className,
                )
            }
            ref={rootRef}
        >
            {textbox.enabled
                ? (
                    <div className={s.textbox}>
                        <div
                            className={cn(
                                s.textboxBg,
                            )}
                            style={textBoxBgStyles}
                        />
                        <div
                            className={cn(
                                s.textboxBgBorder,
                                s[`textboxBgBorder-${textbox.stroke.fill.fillType}`],
                            )}
                            style={textBoxBgBorderStyles}
                        />
                        <div className={cn(
                            s.textboxContent,
                            {
                                [s.textboxContentRounded]: Number(textbox.borderRadius) >= 50,
                            },
                        )}>
                            {content}
                        </div>
                    </div>
                ): content
            }
        </div>
    );
};
