// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FieldEffects__effect--xNACn {
  width: 200px;
  margin-right: 5px;
}

.FieldEffects__speed--U_iXI {
  width: 145px;
}`, "",{"version":3,"sources":["webpack://./src/base/views/pages/StreamerSettings/components/AlertSettings/AlertsSettingsType/components/Elements/components/FormSwitcher/components/FieldEffects/FieldEffects.scss"],"names":[],"mappings":"AAIA;EACI,YAAA;EACA,iBAAA;AAHJ;;AAMA;EACI,YAAA;AAHJ","sourcesContent":[".buildIn {\n    //margin-right: 15px;\n}\n\n.effect {\n    width: 200px;\n    margin-right: 5px;\n}\n\n.speed {\n    width: 145px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"effect": `FieldEffects__effect--xNACn`,
	"speed": `FieldEffects__speed--U_iXI`
};
export default ___CSS_LOADER_EXPORT___;
