import { ColorPicker, ISelectOption, Select, useClickOutside } from '@hyperclap/ui';
import { noop } from '@hyperclap/utils';
import cn from 'classnames';
import * as React from 'react';
import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { IconCloseLine } from '@assets/images/svg';
import { FillType, IFillStyle } from '@typings';


import s from './FieldColorPicker.scss';
import { Field } from '../Field';
import { FormRow } from '../FormRow';
import { FormRowField } from '../FormRowField';

export interface IFieldColorPickerProps {
    className?: string;
    caption?: string;
    value?: IFillStyle;
    isFlatOnly?: boolean;
    disabled?: boolean;
    onChange?(value: IFillStyle): void
}

export const FieldColorPicker = (props: IFieldColorPickerProps) => {
    const {
        className,
        value,
        caption,
        isFlatOnly,
        disabled,
        onChange = noop,
    } = props;
    const [isTooltipDisplayed, setIsTooltipDisplayed] = useState(false);
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`streamer-settings.alerts-type.forms.${id}`);
    const items = Object.values(FillType).map((item) => ({
        value: item,
        title: t(`fillTypes.${item}`),
    }));
    const bgStyles = value?.fillType === FillType.FLAT
        ? { backgroundColor: value?.primaryColor }
        : { background: `linear-gradient(${value?.primaryColor}, ${value?.secondaryColor})` };
    const rootRef = useRef<HTMLDivElement>(null);

    const handleChange = (fill: Partial<IFillStyle>) => {
        onChange(
            {
                ...value,
                ...fill,
            } as IFillStyle,
        );
    };

    const handleTypeChange = (selection?: ISelectOption) => {
        handleChange({
            fillType: selection?.value as FillType,
        });
    };

    const handlePrimaryColor = (primaryColor: string) => {
        handleChange({
            primaryColor,
        });
    };

    const handleSecondaryColor = (secondaryColor: string) => {
        handleChange({
            secondaryColor,
        });
    };

    const handleBackgroundClick = () => {
        if (!disabled) {
            setIsTooltipDisplayed(true);
        }
    };

    const handleCloseClick = () => {
        setIsTooltipDisplayed(false);
    };

    useClickOutside({
        controlledElementRef: rootRef,
        handler: () => setIsTooltipDisplayed(false),
    });

    return (
        <Field
            className={className}
            label={caption}
            content={(
                <div
                    className={cn(s.root, { [s.disabled]: disabled })}
                    ref={rootRef}
                >
                    {isFlatOnly
                        ? (
                            <ColorPicker
                                classNames={{
                                    picker: s.picker,
                                    pickerColor: s.pickerColor,
                                }}
                                value={value?.primaryColor}
                                withoutButtons={true}
                                disabled={disabled}
                                onChange={handlePrimaryColor}
                            />
                        )
                        : (
                            <>
                                <div
                                    className={s.background}
                                    style={bgStyles}
                                    onClick={handleBackgroundClick}
                                />

                                <div
                                    className={cn(
                                        s.tooltip,
                                        { [s.tooltipDisplayed]: isTooltipDisplayed },
                                    )}
                                >
                                    <FormRow>
                                        <Select
                                            className={s.select}
                                            caption={t('type')}
                                            options={items.map((item) => ({
                                                caption: item.title,
                                                value: item.value,
                                                selected: value?.fillType === item.value,
                                            }))}
                                            onSelectionChanged={handleTypeChange}
                                        />
                                    </FormRow>

                                    <FormRow>
                                        {value?.fillType === FillType.FLAT && (
                                            <ColorPicker
                                                caption={t('color')}
                                                classNames={{
                                                    picker: s.picker,
                                                    pickerColor: s.pickerColor,
                                                }}
                                                value={value?.primaryColor}
                                                withoutButtons={true}
                                                onChange={handlePrimaryColor}
                                            />
                                        )}

                                        {value?.fillType === FillType.GRADIENT && (
                                            <>
                                                <FormRowField>
                                                    <ColorPicker
                                                        caption={t('gradientStart')}
                                                        classNames={{
                                                            picker: s.picker,
                                                            pickerColor: s.pickerColor,
                                                        }}
                                                        value={value?.primaryColor}
                                                        withoutButtons={true}
                                                        onChange={handlePrimaryColor}
                                                    />
                                                </FormRowField>
                                                <FormRowField>
                                                    <ColorPicker
                                                        caption={t('gradientEnd')}
                                                        classNames={{
                                                            picker: s.picker,
                                                            pickerColor: s.pickerColor,
                                                        }}
                                                        value={value?.secondaryColor}
                                                        withoutButtons={true}
                                                        onChange={handleSecondaryColor}
                                                    />
                                                </FormRowField>
                                            </>
                                        )}
                                    </FormRow>

                                    <IconCloseLine
                                        className={s.close}
                                        onClick={handleCloseClick}
                                    />
                                </div>
                            </>
                        )
                    }
                </div>
            )}
        />
    );
};
