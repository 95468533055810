import { delay } from '@hyperclap/utils';
import { useState } from 'react';

import { useApi } from '@hooks';
import { IAlertTypeItem, IFillStyle } from '@typings';
import { EAnimationState } from '@views/fragments/Alert';

interface IUsePreviewParams {
    alert: IAlertTypeItem;
}

const DEFAULT_BG_COLOR = '#2093F1';

export const usePreview = (params: IUsePreviewParams) => {
    const { alert } = params;
    const {
        stickers: {
            useSendRandomStickerMutation,
        },
    } = useApi();
    const [sendRandomSticker] = useSendRandomStickerMutation();
    const [animationState, setAnimationState] = useState(EAnimationState.NONE);
    const [isOriginalSize, setIsOriginalSize] = useState(false);
    const [bgColor, setBgColor] = useState(DEFAULT_BG_COLOR);

    const onPreviewInClick = async () => {
        setAnimationState(EAnimationState.NONE);

        await delay(0);

        setAnimationState(EAnimationState.OUT);

        await delay(2000);

        setAnimationState(EAnimationState.IN);
    };

    const onPreviewOutClick = async () => {
        setAnimationState(EAnimationState.NONE);

        await delay(0);

        setAnimationState(EAnimationState.OUT);
    };

    const onObsTestClick = async () => {
        await sendRandomSticker({
            fullscreen: false,
            alert,
        });
    };

    const onRelativeSizeClick = () => setIsOriginalSize(false);

    const onOriginalSizeClick = () => setIsOriginalSize(true);

    const onBgColorChange = (value: IFillStyle) => setBgColor(value?.primaryColor);

    return {
        animationState,
        isOriginalSize,
        bgColor,

        onPreviewInClick,
        onPreviewOutClick,
        onObsTestClick,
        onRelativeSizeClick,
        onOriginalSizeClick,
        onBgColorChange,
    };
};
