import {
    AlertLayout,
    AlertPosition,
    AlertSize,
    AlignText,
    ETextBoxBorderRadius,
    FillType,
    FontSize,
    GraphicsPosition,
    IAlertTypeItem,
    StreamojiPosition,
    StreamojiSize,
    StreamojiType,
    StrokeSize,
    TextPosition,
    TextShadowBlur,
    TextShadowIndent,
    VisualEffect,
    VisualEffectSpeed,
} from '@typings';

import { EDuration, EFontFamily, EOpacity } from './AlertsSettingsType/enums';

const DEFAULT_OFFSET_X = 10;
const DEFAULT_OFFSET_Y = 10;

export const DEFAULT_GRADIENT_COLOR_START = '#FFE600';
export const DEFAULT_GRADIENT_COLOR_END = '#FF0000';

export const VISUAL_EFFECTS_IN = [
    VisualEffect.BACK_IN_DOWN,
    VisualEffect.BACK_IN_LEFT,
    VisualEffect.BACK_IN_RIGHT,
    VisualEffect.BACK_IN_UP,

    VisualEffect.BOUNCE_IN,
    VisualEffect.BOUNCE_IN_DOWN,
    VisualEffect.BOUNCE_IN_LEFT,
    VisualEffect.BOUNCE_IN_RIGHT,
    VisualEffect.BOUNCE_IN_UP,

    VisualEffect.FADE_IN,
    VisualEffect.FADE_IN_DOWN,
    VisualEffect.FADE_IN_DOWN_BIG,
    VisualEffect.FADE_IN_LEFT,
    VisualEffect.FADE_IN_LEFT_BIG,
    VisualEffect.FADE_IN_RIGHT,
    VisualEffect.FADE_IN_RIGHT_BIG,
    VisualEffect.FADE_IN_UP,
    VisualEffect.FADE_IN_UP_BIG,
    VisualEffect.FADE_IN_TOP_LEFT,
    VisualEffect.FADE_IN_TOP_RIGHT,
    VisualEffect.FADE_IN_BOTTOM_LEFT,
    VisualEffect.FADE_IN_BOTTOM_RIGHT,

    VisualEffect.ROTATE_IN,
    VisualEffect.ROTATE_IN_DOWN_LEFT,
    VisualEffect.ROTATE_IN_DOWN_RIGHT,
    VisualEffect.ROTATE_IN_UP_LEFT,
    VisualEffect.ROTATE_IN_UP_RIGHT,

    VisualEffect.ZOOM_IN,
    VisualEffect.ZOOM_IN_DOWN,
    VisualEffect.ZOOM_IN_LEFT,
    VisualEffect.ZOOM_IN_RIGHT,
    VisualEffect.ZOOM_IN_UP,

    VisualEffect.SLIDE_IN_DOWN,
    VisualEffect.SLIDE_IN_LEFT,
    VisualEffect.SLIDE_IN_RIGHT,
    VisualEffect.SLIDE_IN_UP,
];

export const VISUAL_EFFECTS_OUT = [
    VisualEffect.BACK_OUT_DOWN,
    VisualEffect.BACK_OUT_LEFT,
    VisualEffect.BACK_OUT_RIGHT,
    VisualEffect.BACK_OUT_UP,

    VisualEffect.BOUNCE_OUT,
    VisualEffect.BOUNCE_OUT_DOWN,
    VisualEffect.BOUNCE_OUT_LEFT,
    VisualEffect.BOUNCE_OUT_RIGHT,
    VisualEffect.BOUNCE_OUT_UP,

    VisualEffect.FADE_OUT,
    VisualEffect.FADE_OUT_DOWN,
    VisualEffect.FADE_OUT_DOWN_BIG,
    VisualEffect.FADE_OUT_LEFT,
    VisualEffect.FADE_OUT_LEFT_BIG,
    VisualEffect.FADE_OUT_RIGHT,
    VisualEffect.FADE_OUT_RIGHT_BIG,
    VisualEffect.FADE_OUT_UP,
    VisualEffect.FADE_OUT_UP_BIG,
    VisualEffect.FADE_OUT_TOP_LEFT,
    VisualEffect.FADE_OUT_TOP_RIGHT,
    VisualEffect.FADE_OUT_BOTTOM_LEFT,
    VisualEffect.FADE_OUT_BOTTOM_RIGHT,

    VisualEffect.ROTATE_OUT,
    VisualEffect.ROTATE_OUT_DOWN_LEFT,
    VisualEffect.ROTATE_OUT_DOWN_RIGHT,
    VisualEffect.ROTATE_OUT_UP_LEFT,
    VisualEffect.ROTATE_OUT_UP_RIGHT,

    VisualEffect.ZOOM_OUT,
    VisualEffect.ZOOM_OUT_DOWN,
    VisualEffect.ZOOM_OUT_LEFT,
    VisualEffect.ZOOM_OUT_RIGHT,
    VisualEffect.ZOOM_OUT_UP,

    VisualEffect.SLIDE_OUT_DOWN,
    VisualEffect.SLIDE_OUT_LEFT,
    VisualEffect.SLIDE_OUT_RIGHT,
    VisualEffect.SLIDE_OUT_UP,
];

export const VISUAL_EFFECT_SPEED_MAP = {
    [VisualEffectSpeed.SLOW]: 3,
    [VisualEffectSpeed.MEDIUM]: 2,
    [VisualEffectSpeed.FAST]: 1,
};

export const DEFAULT_ALERT_STYLE: Omit<IAlertTypeItem, 'id' | 'isActive' | 'type'> = {
    position: AlertPosition.BOTTOM,
    offsetX: DEFAULT_OFFSET_X,
    offsetY: DEFAULT_OFFSET_Y,
    size: AlertSize.M,

    alertStyle: {
        name: '',
        isSystem: false,
        layout: AlertLayout.HORIZONTAL,
        duration: Number(EDuration.S_30) * 1000,

        avatar: {
            enabled: true,
            type: StreamojiType.ROUND,
            position: StreamojiPosition.LEFT,
            size: StreamojiSize.M,
            background: {
                fillType: FillType.GRADIENT,
                primaryColor: '#8f00ff',
                secondaryColor: '#4b00aa',
            },
            stroke: {
                enabled: true,
                size: StrokeSize.M,
                fill: {
                    fillType: FillType.GRADIENT,
                    primaryColor: DEFAULT_GRADIENT_COLOR_START,
                    secondaryColor: DEFAULT_GRADIENT_COLOR_END,
                },
            },
            effects: {
                in: {
                    effect: VisualEffect.FADE_IN,
                    speed: VisualEffectSpeed.FAST,
                },
                out: {
                    effect: VisualEffect.FADE_OUT,
                    speed: VisualEffectSpeed.FAST,
                },
            },
        },

        title: {
            enabled: true,
            fontFamily: EFontFamily.ARIAL,
            fontSize: FontSize._20,
            fill: {
                fillType: FillType.FLAT,
                primaryColor: 'yellow',
                secondaryColor: '',
            },
            variablesFill: {
                fillType: FillType.GRADIENT,
                primaryColor: 'red',
                secondaryColor: 'blue',
            },
            stroke: {
                enabled: true,
                size: StrokeSize.M,
                fill: {
                    fillType: FillType.FLAT,
                    primaryColor: '#000',
                    secondaryColor: '',
                },
            },
            textShadow: {
                enabled: true,
                indent: TextShadowIndent.I_0,
                fill: {
                    fillType: FillType.FLAT,
                    primaryColor: '#000',
                    secondaryColor: '',
                },
                blur: TextShadowBlur.B_1,
            },
            position: TextPosition.TOP,
            align: AlignText.LEFT,
            text: '%NICK% purchased',
            effects: {
                in: {
                    effect: VisualEffect.FADE_IN,
                    speed: VisualEffectSpeed.FAST,
                },
                out: {
                    effect: VisualEffect.FADE_OUT,
                    speed: VisualEffectSpeed.FAST,
                },
            },
        },

        text: {
            enabled: true,
            fontFamily: EFontFamily.ARIAL,
            fontSize: FontSize._20,
            fill: {
                fillType: FillType.FLAT,
                primaryColor: 'green',
                secondaryColor: '',
            },
            variablesFill: {
                fillType: FillType.FLAT,
                primaryColor: 'blue',
                secondaryColor: '',
            },

            stroke: {
                enabled: true,
                size: StrokeSize.M,
                fill: {
                    fillType: FillType.FLAT,
                    primaryColor: '#000',
                    secondaryColor: '',
                },
            },
            textShadow: {
                enabled: true,
                indent: TextShadowIndent.I_0,
                fill: {
                    fillType: FillType.FLAT,
                    primaryColor: '#000',
                    secondaryColor: '',
                },
                blur: TextShadowBlur.B_1,
            },
            align: AlignText.LEFT,
            text: '%VALUE% %COINS%',
            effects: {
                in: {
                    effect: VisualEffect.FADE_IN,
                    speed: VisualEffectSpeed.FAST,
                },
                out: {
                    effect: VisualEffect.FADE_OUT,
                    speed: VisualEffectSpeed.FAST,
                },
            },
        },

        shadow: {
            enabled: true,
            fill: {
                fillType: FillType.FLAT,
                primaryColor: '',
                secondaryColor: '',
            },
        },

        textbox: {
            enabled: true,
            borderRadius: ETextBoxBorderRadius.R_30,
            opacity: Number(EOpacity.O_30),
            fill: {
                fillType: FillType.FLAT,
                primaryColor: '#000',
                secondaryColor: '',
            },
            stroke: {
                enabled: true,
                size: StrokeSize.M,
                fill: {
                    fillType: FillType.FLAT,
                    primaryColor: '#fff',
                    secondaryColor: '',
                },
            },
            effects: {
                in: {
                    effect: VisualEffect.FADE_IN,
                    speed: VisualEffectSpeed.FAST,
                },
                out: {
                    effect: VisualEffect.FADE_OUT,
                    speed: VisualEffectSpeed.FAST,
                },
            },
        },

        graphics: {
            enabled: true,
            position: GraphicsPosition.BEHIND,
            source: '',

            effects: {
                in: {
                    effect: VisualEffect.FADE_IN,
                    speed: VisualEffectSpeed.FAST,
                },
                out: {
                    effect: VisualEffect.FADE_OUT,
                    speed: VisualEffectSpeed.FAST,
                },
            },
        },
    },
};
