import { Column, Row } from '@hyperclap/ui';
import cn from 'classnames';
import * as React from 'react';

import { IconCheckLine } from '@assets/images/svg';
import { AlertPosition } from '@typings';

import s from './DisplayPosition.scss';

interface IDisplayPositionProps {
    className?: string;
    position: AlertPosition;
    onChange(value: AlertPosition): void;
}

export const DisplayPosition = (props: IDisplayPositionProps) => {
    const {
        className,
        position,
        onChange,
    } = props;

    const handlePositionClick = (value: AlertPosition) => () => onChange(value);

    return (
        <Column className={className}>
            <Row className={s.row}>
                <div
                    className={
                        cn(
                            s.item,
                            s.itemLeftTop,
                            { [s.itemActive]: position === AlertPosition.TOP_LEFT },
                        )
                    }
                    onClick={handlePositionClick(AlertPosition.TOP_LEFT)}
                >
                    <IconCheckLine className={s.itemIcon}/>
                </div>
                <div
                    className={
                        cn(
                            s.item,
                            { [s.itemActive]: position === AlertPosition.TOP },
                        )
                    }

                    onClick={handlePositionClick(AlertPosition.TOP)}
                >
                    <IconCheckLine className={s.itemIcon}/>
                </div>
                <div
                    className={
                        cn(
                            s.item,
                            s.itemRightTop,
                            { [s.itemActive]: position === AlertPosition.TOP_RIGHT },
                        )
                    }
                    onClick={handlePositionClick(AlertPosition.TOP_RIGHT)}
                >
                    <IconCheckLine className={s.itemIcon}/>
                </div>
            </Row>

            <Row className={s.row}>
                <div
                    className={
                        cn(
                            s.item,
                            { [s.itemActive]: position === AlertPosition.LEFT },
                        )
                    }
                    onClick={handlePositionClick(AlertPosition.LEFT)}
                >
                    <IconCheckLine className={s.itemIcon}/>
                </div>
                <div
                    className={
                        cn(
                            s.item,
                            { [s.itemActive]: position === AlertPosition.CENTER },
                        )
                    }
                    onClick={handlePositionClick(AlertPosition.CENTER)}
                >
                    <IconCheckLine className={s.itemIcon}/>
                </div>
                <div
                    className={
                        cn(
                            s.item,
                            { [s.itemActive]: position === AlertPosition.RIGHT },
                        )
                    }
                    onClick={handlePositionClick(AlertPosition.RIGHT)}
                >
                    <IconCheckLine className={s.itemIcon}/>
                </div>
            </Row>

            <Row className={s.row}>
                <div
                    className={
                        cn(
                            s.item,
                            s.itemLeftBottom,
                            { [s.itemActive]: position === AlertPosition.BOTTOM_LEFT },
                        )
                    }
                    onClick={handlePositionClick(AlertPosition.BOTTOM_LEFT)}
                >
                    <IconCheckLine className={s.itemIcon}/>
                </div>
                <div
                    className={
                        cn(
                            s.item,
                            { [s.itemActive]: position === AlertPosition.BOTTOM },
                        )
                    }
                    onClick={handlePositionClick(AlertPosition.BOTTOM)}
                >
                    <IconCheckLine className={s.itemIcon}/>
                </div>
                <div
                    className={
                        cn(
                            s.item,
                            s.itemRightBottom,
                            { [s.itemActive]: position === AlertPosition.BOTTOM_RIGHT },
                        )
                    }
                    onClick={handlePositionClick(AlertPosition.BOTTOM_RIGHT)}
                >
                    <IconCheckLine className={s.itemIcon}/>
                </div>
            </Row>
        </Column>
    );
};
