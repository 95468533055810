// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.AlertsSettings__root--ilbuV {
  margin-top: 15px;
}

.AlertsSettings__title--H9HTA {
  margin-bottom: 15px;
}

.AlertsSettings__content--FkUKn {
  flex: 1;
}

.AlertsSettings__preview--qQXpV {
  width: 500px;
}

.AlertsSettings__items--ypUe6 {
  width: 100%;
}`, "",{"version":3,"sources":["webpack://./src/base/views/pages/StreamerSettings/components/AlertSettings/AlertsSettings/AlertsSettings.scss"],"names":[],"mappings":"AAAA;EACI,gBAAA;AACJ;;AAEA;EACI,mBAAA;AACJ;;AAEA;EACI,OAAA;AACJ;;AAEA;EACI,YAAA;AACJ;;AAEA;EACI,WAAA;AACJ","sourcesContent":[".root {\n    margin-top: 15px;\n}\n\n.title {\n    margin-bottom: 15px;\n}\n\n.content {\n    flex: 1;\n}\n\n.preview {\n    width: 500px;\n}\n\n.items {\n    width: 100%;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"root": `AlertsSettings__root--ilbuV`,
	"title": `AlertsSettings__title--H9HTA`,
	"content": `AlertsSettings__content--FkUKn`,
	"preview": `AlertsSettings__preview--qQXpV`,
	"items": `AlertsSettings__items--ypUe6`
};
export default ___CSS_LOADER_EXPORT___;
