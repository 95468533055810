import { useState } from 'react';

import { EElementMenuItem } from '../../../../enums';

export const useElements = () => {
    const [activeMenuItem, setActiveMenuItem] = useState(EElementMenuItem.STREAMOJI);

    const onActiveMenuItemChange = (value: EElementMenuItem) => setActiveMenuItem(value);

    return {
        activeMenuItem,
        onActiveMenuItemChange,
    };
};
