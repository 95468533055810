import { noop } from '@hyperclap/utils';
import cn from 'classnames';
import React from 'react';

import { IVoiceTag } from '@typings';

import s from './VoiceTag.scss';

interface IVoiceTagProps {
    selected?: boolean;
    tag: IVoiceTag;
    onClick?: (tag: IVoiceTag) => void;
}

export const VoiceTag = (props: IVoiceTagProps) => {
    const { tag, selected, onClick = noop } = props;

    return (
        <div className={cn(s.tag, { [s.tagSelected]: selected })} onClick={() => onClick(tag)}>
            {tag.tag}
        </div>
    );
};
