import { useLogger } from '@hyperclap/ui';
import { useEffect } from 'react';

type TVolumeAdjustable = HTMLAudioElement | HTMLVideoElement;

const DELTA = 0.01;

export const useSmoothVolumeControl = () => {
    const logger = useLogger({ target: useSmoothVolumeControl.name, showTimestamp: true });

    const fade = (player: TVolumeAdjustable, target: number, duration: number) => {
        logger.debug(player);
        if (player === null) return;

        const from = player.volume;
        const to = target / 100;

        if (from === to) return;

        const sign = to > from ? 1 : -1;
        const step = Math.round((Math.abs(to - from)) / DELTA);
        const interval = duration / step;
        logger.debug(`Fade-in volume calculation - From: ${from}, To: ${to}, Duration: ${duration}, Interval: ${interval}, Step: ${step}`);

        const t = setInterval(() => {
            player.volume = Math.min(1, player.volume + DELTA * sign);
            logger.trace(`Player volume set to: ${player.volume}`);

            if (sign > 0) {
                if (player.volume >= to || player.volume >= 1) clearInterval(t);
            } else {
                if (player.volume <= to || player.volume <= 0) clearInterval(t);
            }
        }, interval);
    };

    useEffect(() => {
        logger.info('Fade-in mounted');

        return () => {
            logger.info('Fade-in dismounted');
        };
    }, []);

    return {
        fade,
    };
};
