import {
    Button,
    Column,
    EColumnAlignment,
    EColumnJustification,
    EComponentColor,
    EComponentSize,
    ERowAlignment,
    Icon,
    Label,
    Row,
    Select,
} from '@hyperclap/ui';
import cn from 'classnames';
import React, { useRef } from 'react';
import { useTranslation } from 'react-i18next';

import { IconCheckLine, IconPauseLine, IconPlayLine } from '@assets/images/svg';
import { IVoiceDescriptor } from '@typings';

import s from './VoicesList.scss';
import { ALL_CATEGORY_ID, useVoices } from '../../hooks';
import { VoiceTag } from '../VoiceTag';

export const VoicesList = () => {
    const {
        TEST_PHRASE,
        playedVoiceId,
        selectedCategory,
        voicesCategories,
        voicesInCategory,
        voiceTags,
        selectedTags,
        userVoice,
        playVoice,
        stopVoicePlaying,
        setSelectedCategory,
        switchTagSelection,
        selectUserVoice,
    } = useVoices();

    const {
        t: translate,
    } = useTranslation();

    const player = useRef<HTMLAudioElement>(null);

    const t = (token: string) => translate(`avatar-editor.voices-list.${token}`);

    const onCategoryChanged = (categoryId: string) => {
        stopVoicePlaying(player);
        setSelectedCategory(categoryId);
    };

    const onPlayClick = (descriptor: IVoiceDescriptor) => {
        if (playedVoiceId && playedVoiceId === descriptor.id) {
            stopVoicePlaying(player);
        } else {
            playVoice(descriptor, descriptor.phrase || TEST_PHRASE, player);
        }
    };

    const options = [
        { caption: translate('common.all'), value: ALL_CATEGORY_ID, selected: selectedCategory === ALL_CATEGORY_ID },
        ...(voicesCategories?.map((category) => ({
            caption: category.name,
            value: category.id,
            selected: category.id === selectedCategory,
        })) ?? [] ),
    ];

    const voices = voicesInCategory?.map((descriptor) => (
        <Row key={descriptor.id} className={s.voiceContainer}>
            <Column grow>
                <Row columnGap={10} alignment={ERowAlignment.STRETCH} className={s.voice}>
                    <Column
                        alignment={EColumnAlignment.CENTER}
                        justification={EColumnJustification.CENTER}
                        onClick={() => onPlayClick(descriptor)}
                        className={s.clickable}
                    >
                        <Icon
                            className={cn(s.playIcon, { [s.playIconActive]: playedVoiceId === descriptor.id })}
                            icon={playedVoiceId === descriptor.id ? <IconPauseLine/> : <IconPlayLine/>}
                            color={EComponentColor.WHITE}
                            size={EComponentSize.EXTRA_SMALL}
                        />
                    </Column>
                    <Column grow onClick={() => onPlayClick(descriptor)} className={s.clickable}>
                        <Label
                            caption={descriptor.name}
                            size={EComponentSize.MEDIUM}
                            className={cn(s.name, { [s.nameActive]: playedVoiceId === descriptor.id })}
                        />
                    </Column>
                    <Column onClick={() => onPlayClick(descriptor)} className={s.clickable}/>
                    <Column>
                        <Button
                            icon={<IconCheckLine/>}
                            className={cn(s.selectBtn, { [s.selectBtnSelected]: descriptor.id === userVoice })}
                            onClick={() => selectUserVoice(descriptor)}
                        />
                    </Column>
                </Row>
                <Row padding={{ left: 26, right: 40 }} className={s.voiceTags}>
                    <div onClick={() => onPlayClick(descriptor)}>
                        {descriptor.tags?.join((', '))}
                    </div>
                </Row>
            </Column>
        </Row>
    ));

    const tags = voiceTags?.map((tag, idx) => (
        <VoiceTag
            key={idx}
            tag={tag}
            selected={selectedTags.includes(tag.tag)}
            onClick={() => switchTagSelection(tag)}
        />
    ));

    return (
        <Column grow alignment={EColumnAlignment.STRETCH} justification={EColumnJustification.STRETCH}>
            <audio ref={player}/>
            <Row>
                <Column>
                    <Label padding={{ top: 0 }} caption={t('category')} size={EComponentSize.MEDIUM}/>
                    <Select
                        width={600}
                        options={options}
                        onValueChanged={(value) => onCategoryChanged(value?.toString() ?? '')}
                    />
                    <Row padding={{ top: 10, bottom: 10 }} columnGap={5} className={s.tagsContainer}>
                        {tags}
                    </Row>
                </Column>
            </Row>
            <Column
                grow
                width={600}
                padding={{ top: 20, bottom: 20 }}
                alignment={EColumnAlignment.STRETCH}
            >
                <Column className={s.scrollableAreaContent}>
                    {voices}
                </Column>
            </Column>
        </Column>
    );
};
