import {
    Button,
    Column,
    EColumnJustification,
    EComponentColor,
    ERowAlignment,
    ERowJustification,
    Row,
    TextField,
    useModals,
} from '@hyperclap/ui';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

import { IAlertTypeItem } from '@typings';

import s from './CreateForm.scss';
import { useCreateForm } from './hooks';

interface ICreateFormProps {
    alertStyle: IAlertTypeItem;
}

export const CreateForm = (props: ICreateFormProps) => {
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`streamer-settings.alerts-type.${id}`);
    const tc = (id: string) => translation(`common.${id}`);
    const { closeModal } = useModals();
    const { alertStyle } = props;
    const {
        isSubmitDisabled,
        onNameChange,
        onSubmit,
    } = useCreateForm({
        alertStyle,
    });

    const handleCreateClick = async () => {
        await onSubmit();
        closeModal();
    };

    const handleCancelClick = () => closeModal();

    return (
        <Column
            className={s.root}
            justification={EColumnJustification.SPACE_BETWEEN}
        >
            <Row
                alignment={ERowAlignment.CENTER}
                className={s.form}
            >
                <TextField
                    wrapperClassName={s.input}
                    placeholder={t('styleName')}
                    autoFocus={true}
                    onChanged={onNameChange}
                />
            </Row>

            <Row
                justification={ERowJustification.END}
                alignment={ERowAlignment.TOP}
            >
                <Button
                    className={s.buttonsItem}
                    disabled={isSubmitDisabled}
                    color={EComponentColor.ACCENT}
                    caption={tc('create')}
                    onClick={handleCreateClick}
                />

                <Button
                    className={s.buttonsItem}
                    caption={tc('cancel')}
                    onClick={handleCancelClick}
                />
            </Row>
        </Column>
    );
};
