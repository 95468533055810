import { CSSProperties } from 'react';

import { IStrokeStyle, StrokeSize } from '@typings';

interface IGetOutlinedTextStylesParams {
    stroke: IStrokeStyle;
    numSteps?: number;
}

const SIZE_MAP = {
    [StrokeSize.NONE]: 0,
    [StrokeSize.S]: 2,
    [StrokeSize.M]: 4,
    [StrokeSize.L]: 5,
};

export const getOutlinedTextStyles = (params: IGetOutlinedTextStylesParams) => {
    const {
        stroke,
        numSteps = 32,
    } = params;
    const sizePx = SIZE_MAP[stroke.size];
    const shadows = [] as string[];

    for (let i = 0; i < numSteps; i++) {
        const angle = i * 360 / numSteps;
        const x = Math.cos(angle) * sizePx;
        const y = Math.sin(angle) * sizePx;

        shadows.push(`${x}px ${y}px ${stroke.fill.primaryColor}`);
    }

    return {
        textShadow: shadows.join(','),
    } as CSSProperties;
};
