/* eslint-disable max-len */

import {
    Button,
    Column,
    Divider,
    EComponentColor,
    EComponentSize,
    ERowAlignment,
    ERowJustification,
    Label,
    Loader,
    Row,
} from '@hyperclap/ui';
import cn from 'classnames';
import * as React from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';

import { IconLightning } from '@assets/images/svg';
import { useAppContext } from '@hooks';
import { AlertType } from '@typings';

import s from './AlertsSettingsType.scss';
import {
    Elements,
    // General,
    Preset,
    PositionAndSize,
    Sound,
} from './components';
import { useAlertsSettingsType } from './hooks';
import { Preview } from '../components/Preview';

interface IRouteParams extends Record<string, string>{
    type: AlertType;
}

export const AlertsSettingsType = () => {
    const { type } = useParams<IRouteParams>();
    const app = useAppContext();
    const {
        currentUser: { currentUser },
    } = app;
    const alertsSettingsType = useAlertsSettingsType({
        type: type as AlertType,
    });
    const {
        isLoading,
        alertStyle,
        onSubmit,
        onReset,
    } = alertsSettingsType;
    const { t: translation } = useTranslation();
    const t = (id: string) => translation(`streamer-settings.alerts-type.${id}`);
    const ta = (id: string) => translation(`streamer-settings.alerts.${id}`);
    const tc = (id: string) => translation(`common.${id}`);

    return isLoading
        ? <Loader/>
        : (
            <Row className={s.root} alignment={ERowAlignment.TOP}>
                <Column className={s.content}>
                    <Label
                        className={s.title}
                        caption={ta(`types.${type}`)}
                        size={EComponentSize.LARGE}
                    />

                    <Column className={cn(s.column, s.columnPositionAndSize)}>
                        <Label className={s.columnTitle} size={EComponentSize.MEDIUM} caption={t('positionAndSize')}/>

                        <PositionAndSize alertsSettingsType={alertsSettingsType}/>

                        <Divider/>
                    </Column>

                    <Column className={cn(s.column, s.columnPreset)}>
                        <Label className={s.columnTitle} size={EComponentSize.MEDIUM} caption={t('visual')}/>

                        <Preset alertsSettingsType={alertsSettingsType}/>
                    </Column>

                    <Column className={cn(s.column, s.columnElements)}>
                        <Elements alertsSettingsType={alertsSettingsType}/>
                    </Column>

                    <Column className={cn(s.column, s.columnSound)}>
                        <Label className={s.columnTitle} size={EComponentSize.MEDIUM} caption={t('sound')}/>

                        <Sound alertsSettingsType={alertsSettingsType}/>
                    </Column>

                    <Column className={cn(s.column)}>
                        <Row justification={ERowJustification.SPACE_BETWEEN}>
                            <Row>
                                <Button
                                    className={s.buttonsSave}
                                    color={EComponentColor.ACCENT}
                                    caption={tc('save')}
                                    onClick={onSubmit}
                                />
                                <Button
                                    captionClassName={s.applyToAllCaption}
                                    caption={(
                                        <>
                                            <IconLightning className={s.applyToAllIcon}/>
                                            {t('applyToAll')}
                                        </>
                                    )}
                                />
                            </Row>
                            <Row>
                                <Button
                                    className={s.buttonsReset}
                                    caption={tc('reset')}
                                    onClick={onReset}
                                />
                            </Row>
                        </Row>
                    </Column>
                </Column>

                <Column className={s.preview}>
                    <Preview
                        alert={alertStyle}
                        currentUser={currentUser}
                    />
                </Column>
            </Row>
        );
};
