import { EtmaRating } from '@typings';

export enum ContentMarkAction {
    ETMA = 'ETMA',
    TWITCH_UNSAFE = 'TWITCH_UNSAFE',
    REJECT = 'REJECT',
}

export interface IContentMark {
    _id: string;
    id: string;
    name: string;
    comment?: string;
    etmaRating?: EtmaRating;
    category: string;
    isTextCheck: boolean;
    isPicCheck: boolean;
    isFaceCheck: boolean;
    isAgeCheck: boolean;
    isSndCheck: boolean;
    actions?: ContentMarkAction[],
    createdAt: number;
    createdBy: boolean;
    order: number;
}

export interface IContentMarksByCategory {
    [key: string]: IContentMark[];
}
