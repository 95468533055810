import { useState } from 'react';

import { useApi } from '@hooks';
import { IAlertTypeItem } from '@typings';

interface IUseCreateFormParams {
    alertStyle: IAlertTypeItem;
}

export const useCreateForm = (params: IUseCreateFormParams) => {
    const { alertStyle } = params;
    const {
        alerts: {
            useCreateAlertStyleMutation,
        },
    } = useApi();
    const [createAlertStyle] = useCreateAlertStyleMutation();
    const [name, setName] = useState('');
    const isSubmitDisabled = name.trim().length === 0;

    const onNameChange = (value: string) => setName(value);

    const onSubmit = async () => {
        await createAlertStyle({
            ...alertStyle.alertStyle,
            name,
        });
    };

    return {
        name,
        isSubmitDisabled,
        onNameChange,
        onSubmit,
    };
};
