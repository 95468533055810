// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../../../../../../../../../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.TextBoxForm__styleItem--rbJZ3 {
  width: 90px;
}

.TextBoxForm__borderRadiusSelect--l8cmV,
.TextBoxForm__opacitySelect--i15pZ,
.TextBoxForm__strokeSelect--gn80Q {
  width: 100px;
  margin-right: 5px;
}
.TextBoxForm__borderRadiusSelectControlInput--_THqW,
.TextBoxForm__opacitySelectControlInput--Cacjv,
.TextBoxForm__strokeSelectControlInput--IkBOj {
  min-width: 20px;
}

.TextBoxForm__strokeSelect--gn80Q {
  width: 125px;
}

.TextBoxForm__borderRadius--TmufF {
  margin-right: 10px;
}`, "",{"version":3,"sources":["webpack://./src/base/views/pages/StreamerSettings/components/AlertSettings/AlertsSettingsType/components/Elements/components/FormSwitcher/components/forms/TextBoxForm/TextBoxForm.scss"],"names":[],"mappings":"AACI;EACI,WAAA;AAAR;;AAOI;;;EACI,YAAA;EACA,iBAAA;AAFR;AAIQ;;;EACI,eAAA;AAAZ;;AAKA;EACI,YAAA;AAFJ;;AAKA;EACI,kBAAA;AAFJ","sourcesContent":[".style {\n    &Item {\n        width: 90px;\n    }\n}\n\n.borderRadius,\n.opacity,\n.stroke {\n    &Select {\n        width: 100px;\n        margin-right: 5px;\n\n        &ControlInput {\n            min-width: 20px;\n        }\n    }\n}\n\n.strokeSelect {\n    width: 125px;\n}\n\n.borderRadius {\n    margin-right: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"styleItem": `TextBoxForm__styleItem--rbJZ3`,
	"borderRadiusSelect": `TextBoxForm__borderRadiusSelect--l8cmV`,
	"opacitySelect": `TextBoxForm__opacitySelect--i15pZ`,
	"strokeSelect": `TextBoxForm__strokeSelect--gn80Q`,
	"borderRadiusSelectControlInput": `TextBoxForm__borderRadiusSelectControlInput--_THqW`,
	"opacitySelectControlInput": `TextBoxForm__opacitySelectControlInput--Cacjv`,
	"strokeSelectControlInput": `TextBoxForm__strokeSelectControlInput--IkBOj`,
	"borderRadius": `TextBoxForm__borderRadius--TmufF`
};
export default ___CSS_LOADER_EXPORT___;
