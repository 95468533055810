import { useState } from 'react';

import { AlertType, IAlertTypeItem } from '@typings';

interface IUseAlertTypeItemParams {
    type: AlertType;
    item?: IAlertTypeItem;
    onActiveChange(value: boolean): void;
}

export const useAlertTypeItem = (params: IUseAlertTypeItemParams) => {
    const {
        item,
        onActiveChange,
    } = params;
    const [isActive, setIsActive] = useState(item ? item.isActive : true);

    const handleActiveChange = (value: boolean) => {
        setIsActive(value);
        onActiveChange(value);
    };

    return {
        isActive,
        handleActiveChange,
    };
};
